import {useStore} from "~/store";

export default defineNuxtPlugin({
    name: 'intercom-plugin',
    enforce: 'pre', // or 'post'
    hooks: {
        'app:mounted'() {
            (async () => {
                const store = useStore()

                const {default: Intercom} = await import('@intercom/messenger-js-sdk') // Динамический импорт

                Intercom({
                    app_id: 'jijl2xhy',
                    ...(store.user
                        ? {
                            avatar: store.user.avatar_url,
                            user_id: store.user.steamid64,
                            name: store.user.persona_name
                        }
                        : {}),
                    horizontal_padding: 150,
                    vertical_padding: 55
                })

            })();
        },
    },
})
