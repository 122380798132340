import {defineStore} from 'pinia'
import {getCategoryMenu} from '~/utils/api'
import {EventBus} from '~/composables/bus'
import {computed, ref} from 'vue'
import type {Currencies} from "~/types/Currencies";
import type {
    AddonFullType,
    Category,
    ItemColorGroupResult,
    MarketStat,
    UserFullInfo
} from "~/graphql/generated/schema-types";


export const useStore = defineStore('store', () => {
    const user = ref<UserFullInfo | null>(null)
    const currency = ref<keyof Currencies>('usd')
    const currencies = ref<Currencies>({
        usd: 1,
        eur: 0,
        try: 0,
        gbp: 0,
        krw: 0,
        ils: 0,
        jpy: 0,
        rub: 0,
        cny: 0
    })
    const categories = ref<Category[]>([])
    const markets_stats = ref<MarketStat[]>([])
    const colorGroups = ref([] as ItemColorGroupResult[])
    const userAddons = ref<AddonFullType[]>([])
    // const loadUser = async () => {
    //     if (!user.value) {
    //         try {
    //             const {data, errors} = await getUserFullInfo()
    //             if (data?.userInfo) {
    //                 user.value = data?.userInfo
    //             }
    //         } catch (e: any) {
    //             EventBus.$emit('notify', {
    //                 success: false,
    //                 msg: e.message || 'An error occurred'
    //             })
    //             console.log(`Error `, e)
    //         }
    //     }
    // }

    const getCategories = async () => {
        if (!categories.value || !categories.value.length) {
            try {
                const {data, errors} = await getCategoryMenu()

                if (errors?.length) {
                    throw new Error(errors[0]?.message)
                }
                const items = data['getCategories']
                const index = items.findIndex(item => item.category === 'Other')
                if (index !== -1 && index !== items.length - 1) {
                    const [otherCategory] = items.splice(index, 1)
                    items.push(otherCategory)
                }

                return items
            } catch (e: any) {
                console.log('getCategoryMenu() error: ', e.message)
                EventBus.$emit('notify', {
                    success: false,
                    msg: e.message || 'An error occurred'
                })
                throw new Error(e.message)
            }
        }
    }

    const setCurrencies = (value: Currencies) => {
        currencies.value = value
    }

    const setCurrency = (value: keyof Currencies) => {
        currency.value = value
    }

    const rate = computed(() => currencies.value[currency.value])

    return {
        user,
        markets_stats,
        currency,
        currencies,
        categories,
        rate,
        colorGroups,
        userAddons,
        // loadUser,
        getCategories,
        setCurrencies,
        setCurrency,
    }
})
