<template>
  <NuxtLink :to="localePath('/')" class="logo">
    <!--    <img alt="logo" height="30" src="/img/logo.svg" width="100" />-->
    <svg fill="none" viewBox="0 0 115 27" width="100" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M7.83137 16.7505C7.69068 16.7373 7.54628 16.7419 7.40022 16.7804C6.73715 16.9554 6.33947 17.6444 6.5103 18.3197C6.68161 18.9968 7.35829 19.4024 8.01942 19.228C8.68248 19.053 9.08016 18.364 8.90885 17.6868C8.82344 17.3492 8.61144 17.0785 8.33816 16.9141L9.47026 13.3742C9.64423 13.3974 9.82472 13.3895 10.0035 13.3424C10.3648 13.247 10.6548 13.0156 10.8398 12.7176L13.8052 13.9201C13.7837 14.0702 13.7907 14.2275 13.8304 14.3845C13.9893 15.0126 14.6157 15.3896 15.2307 15.2273C15.8457 15.065 16.2148 14.4253 16.0559 13.7973C16.0237 13.6697 15.9684 13.5565 15.9012 13.4529L18.0924 11.0929C18.4268 11.2643 18.8221 11.3191 19.2142 11.2157C20.0732 10.989 20.5907 10.0924 20.3683 9.21312C20.1458 8.33382 19.2698 7.80491 18.4088 8.03209C17.5496 8.25878 17.0322 9.15532 17.2547 10.0346C17.3273 10.3212 17.4701 10.5703 17.66 10.7694L15.517 13.0768C15.2659 12.9274 14.9575 12.8748 14.6538 12.955C14.3789 13.0275 14.1559 13.1994 14.0045 13.4194L11.0342 12.214C11.0726 12.0092 11.0719 11.7958 11.0178 11.5819C10.8222 10.8086 10.0507 10.3442 9.29344 10.544C8.5362 10.7438 8.08201 11.5336 8.27716 12.305C8.37847 12.7054 8.63654 13.0205 8.96439 13.2062L7.83137 16.7505Z"
          fill="#58E2B0" />
      <path
          d="M23.4199 5.22552C21.0238 2.12183 17.3091 0.129333 13.1388 0.129333C5.91235 0.129333 0.0541992 6.11193 0.0541992 13.4918C0.0541992 20.8717 5.91235 26.8544 13.1388 26.8544C17.2783 26.8544 20.969 24.8912 23.3665 21.8268C22.9174 21.4473 22.5257 21.0024 22.2085 20.4896C21.8737 19.9481 21.6652 19.3625 21.5722 18.7555C19.8352 21.6518 16.708 23.5841 13.1388 23.5841C7.68089 23.5841 3.25641 19.0657 3.25641 13.4918C3.25641 7.91802 7.68089 3.39956 13.1388 3.39956C16.711 3.39956 19.8406 5.3352 21.5765 8.23548C21.6749 7.64389 21.8905 7.08061 22.2223 6.56531C22.548 6.04454 22.9525 5.59807 23.4199 5.22552Z"
          fill="#58E2B0" />
      <path
          d="M65.8379 17.9951C65.8379 19.094 65.3895 19.908 64.4929 20.4372C63.6161 20.9459 62.4603 21.2003 61.0256 21.2003H50.3849C49.7871 21.2003 49.1992 21.1392 48.6213 21.017C48.0634 20.8747 47.5553 20.6712 47.097 20.4067C46.6587 20.1218 46.2999 19.786 46.021 19.3993C45.7619 19.0127 45.6323 18.5547 45.6323 18.0257H60.8463V15.1564H50.1757C49.5779 15.1564 49 15.0953 48.442 14.9732C47.904 14.8308 47.4258 14.6273 47.0073 14.3627C46.5889 14.0778 46.2501 13.7421 45.991 13.3554C45.7519 12.9484 45.6323 12.4702 45.6323 11.9208V9.38723C45.6323 8.77674 45.7519 8.24764 45.991 7.79994C46.2501 7.35224 46.5889 6.98596 47.0073 6.70106C47.4258 6.41615 47.904 6.21267 48.442 6.09056C49 5.94811 49.5878 5.87689 50.2056 5.87689H61.0854C62.5201 5.87689 63.6659 6.13127 64.5227 6.64C65.3995 7.12841 65.8379 7.94239 65.8379 9.08198H50.7436V11.9208H61.0555C61.6533 11.9208 62.2412 11.9818 62.819 12.1039C63.3969 12.2057 63.905 12.3786 64.3434 12.6228C64.7817 12.867 65.1404 13.1926 65.4194 13.5996C65.6984 13.9863 65.8379 14.434 65.8379 14.9426V17.9951Z"
          fill="currentColor" />
      <path
          d="M43.8278 9.08198C43.8278 9.65177 43.6585 10.0893 43.3197 10.3945C43.0008 10.6998 42.692 10.9338 42.3932 11.0966C41.9547 11.3815 41.4665 11.6053 40.9285 11.7681C40.3905 11.9106 39.8226 11.9818 39.2248 11.9818H38.7765V9.08198H28.6736V18.0257H38.8064V14.9122H43.768V17.9342C43.768 18.4836 43.6485 18.9618 43.4094 19.3687C43.1703 19.7758 42.8415 20.1116 42.423 20.3761C42.0245 20.6407 41.5463 20.8441 40.9883 20.9867C40.4304 21.1087 39.8425 21.1698 39.2248 21.1698H28.1356C27.538 21.1698 26.97 21.0985 26.432 20.9561C25.894 20.7932 25.4157 20.5796 24.9973 20.315C24.5988 20.0301 24.28 19.6943 24.0409 19.3078C23.8016 18.9211 23.6821 18.4836 23.6821 17.9951V8.99041C23.6821 8.54271 23.8016 8.13571 24.0409 7.76941C24.28 7.38277 24.5988 7.05718 24.9973 6.79263C25.4157 6.50773 25.894 6.28388 26.432 6.12109C26.97 5.95829 27.5479 5.87689 28.1655 5.87689H39.2248C40.6196 5.87689 41.7355 6.15161 42.5725 6.70106C43.4094 7.2505 43.8278 8.04414 43.8278 9.08198Z"
          fill="currentColor" />
      <path
          d="M114.954 9.08198C114.954 9.65177 114.785 10.0893 114.446 10.3945C114.127 10.6998 113.818 10.9338 113.519 11.0966C113.081 11.3815 112.593 11.6053 112.055 11.7681C111.517 11.9106 110.949 11.9818 110.351 11.9818H109.903V9.08198H99.8001V18.0257H109.933V14.9122H114.894V17.9342C114.894 18.4836 114.775 18.9618 114.536 19.3687C114.297 19.7758 113.968 20.1116 113.549 20.3761C113.151 20.6407 112.673 20.8441 112.115 20.9867C111.557 21.1087 110.969 21.1698 110.351 21.1698H99.2621C98.6643 21.1698 98.0965 21.0985 97.5585 20.9561C97.0205 20.7932 96.5421 20.5796 96.1237 20.315C95.7251 20.0301 95.4064 19.6943 95.1672 19.3078C94.9281 18.9211 94.8086 18.4836 94.8086 17.9951V8.99041C94.8086 8.54271 94.9281 8.13571 95.1672 7.76941C95.4064 7.38277 95.7251 7.05718 96.1237 6.79263C96.5421 6.50773 97.0205 6.28388 97.5585 6.12109C98.0965 5.95829 98.6742 5.87689 99.292 5.87689H110.351C111.746 5.87689 112.862 6.15161 113.699 6.70106C114.536 7.2505 114.954 8.04414 114.954 9.08198Z"
          fill="currentColor" />
      <path
          d="M87.0594 21.2003V11.7799L81.0995 18.631L75.0796 11.7799V21.2003H70.0781V5.87689H71.0366C71.296 5.87689 71.6256 5.88709 72.0249 5.90748C72.4441 5.90748 72.8734 5.96865 73.3127 6.09099C73.7718 6.21333 74.2311 6.37645 74.6903 6.58037C75.1495 6.78427 75.5688 7.09012 75.9482 7.49792L81.0995 13.0033L86.191 7.49792C86.5503 7.09012 86.9596 6.78427 87.4188 6.58037C87.8781 6.37645 88.3372 6.21333 88.7965 6.09099C89.2557 5.96865 89.695 5.90748 90.1143 5.90748C90.5335 5.88709 90.873 5.87689 91.1325 5.87689H92.061V21.2003H87.0594Z"
          fill="currentColor" />
    </svg>

    <!--    <svg fill="none" height="39" viewBox="0 0 87 39" width="87" xmlns="http://www.w3.org/2000/svg">-->
    <!--      <path-->
    <!--          d="M13.9262 0.199219C13.9262 0.199219 5 2.56959 2.5 3.19922L10 12.6992L10.5 10.5881L20.1698 14.9955L17.9845 12.1622L24.5403 14.9955L24.2281 12.7918L29.5 17.1992V14.1992L39.837 17.1992L39 14.1992L47.9536 15.3103L45.7684 12.7918H54.8215L52.6363 10.5881L58.5677 9.64366L57.3189 8.69922L63.5625 4.60663L56.5 6.69922L57.9433 3.66218L50.7632 7.75477L53.2606 4.29181L44.8318 7.12514L46.7049 4.92144L36.0909 8.3844L39.837 4.29181L29.2229 7.75477L31.7204 5.23626L26.1012 6.1807L29.8473 2.71774L19 5.23626L20.5 3.03255H13.3019C13.3019 2.50786 13.9262 0.199219 13.9262 0.199219Z"-->
    <!--          fill="#357E68" />-->
    <!--      <path-->
    <!--          d="M6.5 17.3992C6.5 16.7365 7.03726 16.1992 7.7 16.1992H11.3C11.9627 16.1992 12.5 16.7365 12.5 17.3992V19.7992H6.5V17.3992Z"-->
    <!--          fill="#FFC802" />-->
    <!--      <path clip-rule="evenodd"-->
    <!--            d="M9.99936 0C8.2981 0 7.08121 0.732664 6.35476 1.96563C5.66041 3.14411 5.45448 4.70803 5.53804 6.34984C5.70577 9.64578 7.06057 13.629 8.77576 16.5542L9.27578 17.407L9.91411 16.6522C12.0952 14.0731 13.7691 10.0662 14.1503 6.6773C14.3404 4.98757 14.221 3.3534 13.5886 2.11038C13.2671 1.47824 12.8092 0.941272 12.1926 0.565719C11.5761 0.190287 10.8402 0 9.99936 0ZM11.5623 11.0725C11.0023 12.4684 10.2874 13.8071 9.47931 14.9326C8.10246 12.2847 7.07408 8.98741 6.93623 6.27869C6.85881 4.7574 7.0673 3.51419 7.56097 2.67631C8.02249 1.89299 8.76711 1.40009 9.99902 1.4M11.5623 11.0725L12.8372 11.6536C13.5062 9.99992 13.9711 8.27034 14.1503 6.6773C14.299 5.35564 14.2581 4.06797 13.9307 2.97488L13.3015 3.03255C13.3015 2.8395 13.386 2.40496 13.4929 1.93276C13.1967 1.41014 12.8 0.961173 12.289 0.626805L12.2851 0.624288L9.99902 1.4"-->
    <!--            fill="#FFC802"-->
    <!--            fill-rule="evenodd" />-->
    <!--      <path-->
    <!--          d="M48 3.69922C48 4.52765 47.3284 5.19922 46.5 5.19922C45.6716 5.19922 45 4.52765 45 3.69922C45 2.87079 45.6716 2.19922 46.5 2.19922C47.3284 2.19922 48 2.87079 48 3.69922Z"-->
    <!--          fill="#FF5757" />-->
    <!--      <path-->
    <!--          d="M61 9.69922C61 10.5276 60.3284 11.1992 59.5 11.1992C58.6716 11.1992 58 10.5276 58 9.69922C58 8.87079 58.6716 8.19922 59.5 8.19922C60.3284 8.19922 61 8.87079 61 9.69922Z"-->
    <!--          fill="#FF5757" />-->
    <!--      <path-->
    <!--          d="M31 16.6992C31 17.5276 30.3284 18.1992 29.5 18.1992C28.6716 18.1992 28 17.5276 28 16.6992C28 15.8708 28.6716 15.1992 29.5 15.1992C30.3284 15.1992 31 15.8708 31 16.6992Z"-->
    <!--          fill="#FF5757" />-->
    <!--      <path-->
    <!--          d="M5.88875 30.6376C5.78222 30.6278 5.67289 30.6312 5.56229 30.6601C5.06023 30.791 4.75912 31.3066 4.88846 31.812C5.01818 32.3187 5.53055 32.6222 6.03114 32.4917C6.5332 32.3608 6.83431 31.8451 6.7046 31.3383C6.63993 31.0857 6.47941 30.8831 6.27248 30.7601L7.12969 28.1109C7.26142 28.1283 7.39808 28.1224 7.53342 28.0871C7.80701 28.0158 8.02657 27.8426 8.16664 27.6196L10.412 28.5195C10.3957 28.6318 10.401 28.7495 10.4311 28.867C10.5514 29.3371 11.0257 29.6192 11.4914 29.4977C11.957 29.3763 12.2365 28.8976 12.1162 28.4276C12.0918 28.3321 12.0499 28.2474 11.999 28.1698L13.6582 26.4037C13.9114 26.532 14.2107 26.573 14.5076 26.4956C15.1581 26.326 15.5499 25.655 15.3814 24.997C15.213 24.3389 14.5497 23.9431 13.8978 24.1131C13.2472 24.2828 12.8555 24.9537 13.0239 25.6118C13.0789 25.8262 13.187 26.0126 13.3308 26.1616L11.7082 27.8884C11.5181 27.7766 11.2845 27.7373 11.0546 27.7972C10.8464 27.8515 10.6775 27.9801 10.5629 28.1448L8.31391 27.2428C8.34297 27.0895 8.34245 26.9298 8.30147 26.7697C8.15333 26.1909 7.56917 25.8434 6.9958 25.993C6.42244 26.1425 6.07853 26.7336 6.2263 27.3108C6.303 27.6105 6.49841 27.8463 6.74665 27.9852L5.88875 30.6376Z"-->
    <!--          fill="#58E2B0" />-->
    <!--      <path-->
    <!--          d="M17.692 22.013C15.8777 19.6903 13.0651 18.1992 9.90736 18.1992C4.43567 18.1992 0 22.6764 0 28.1992C0 33.722 4.43567 38.1992 9.90736 38.1992C13.0418 38.1992 15.8363 36.73 17.6516 34.4367C17.3115 34.1528 17.015 33.8199 16.7748 33.4361C16.5213 33.0308 16.3634 32.5926 16.293 32.1384C14.9778 34.3058 12.6099 35.7519 9.90736 35.7519C5.77478 35.7519 2.42465 32.3705 2.42465 28.1992C2.42465 24.028 5.77478 20.6465 9.90736 20.6465C12.6122 20.6465 14.9818 22.0951 16.2963 24.2656C16.3708 23.8228 16.534 23.4013 16.7853 23.0157C17.0318 22.6259 17.3381 22.2918 17.692 22.013Z"-->
    <!--          fill="#58E2B0" />-->
    <!--      <path-->
    <!--          d="M49.8101 31.5693C49.8101 32.3916 49.4706 33.0008 48.7917 33.3969C48.1278 33.7776 47.2527 33.9679 46.1663 33.9679H38.1094C37.6567 33.9679 37.2116 33.9222 36.774 33.8308C36.3516 33.7242 35.9668 33.572 35.6198 33.374C35.2879 33.1608 35.0163 32.9095 34.8051 32.6201C34.609 32.3308 34.5108 31.9881 34.5108 31.5921H46.0305V29.4449H37.951C37.4983 29.4449 37.0607 29.3992 36.6383 29.3078C36.2309 29.2012 35.8688 29.0489 35.5519 28.851C35.2351 28.6377 34.9786 28.3865 34.7825 28.0971C34.6014 27.7925 34.5108 27.4347 34.5108 27.0235V25.1275C34.5108 24.6706 34.6014 24.2746 34.7825 23.9396C34.9786 23.6046 35.2351 23.3304 35.5519 23.1172C35.8688 22.904 36.2309 22.7517 36.6383 22.6604C37.0607 22.5538 37.5059 22.5005 37.9736 22.5005H46.2116C47.2979 22.5005 48.1655 22.6908 48.8142 23.0715C49.4781 23.4371 49.8101 24.0462 49.8101 24.899H38.381V27.0235H46.1889C46.6416 27.0235 47.0867 27.0692 47.5243 27.1605C47.9618 27.2367 48.3466 27.3661 48.6785 27.5489C49.0104 27.7316 49.282 27.9753 49.4932 28.2799C49.7045 28.5692 49.8101 28.9042 49.8101 29.2849V31.5693Z"-->
    <!--          fill="white" />-->
    <!--      <path-->
    <!--          d="M33.1445 24.899C33.1445 25.3254 33.0163 25.6529 32.7598 25.8813C32.5183 26.1097 32.2844 26.2849 32.0582 26.4067C31.7262 26.6199 31.3566 26.7874 30.9492 26.9093C30.5418 27.0159 30.1118 27.0692 29.6592 27.0692H29.3197V24.899H21.6701V31.5921H29.3424V29.2622H33.0992V31.5237C33.0992 31.9349 33.0087 32.2927 32.8277 32.5972C32.6466 32.9019 32.3977 33.1532 32.0808 33.3511C31.7791 33.5491 31.417 33.7014 30.9945 33.808C30.572 33.8994 30.1269 33.9451 29.6592 33.9451H21.2627C20.8101 33.9451 20.3801 33.8917 19.9727 33.7852C19.5654 33.6633 19.2032 33.5034 18.8864 33.3054C18.5847 33.0922 18.3432 32.8409 18.1622 32.5516C17.9811 32.2622 17.8906 31.9349 17.8906 31.5693V24.8305C17.8906 24.4955 17.9811 24.1909 18.1622 23.9168C18.3432 23.6274 18.5847 23.3837 18.8864 23.1858C19.2032 22.9726 19.5654 22.805 19.9727 22.6832C20.3801 22.5614 20.8177 22.5005 21.2853 22.5005H29.6592C30.7153 22.5005 31.5603 22.7061 32.194 23.1172C32.8277 23.5284 33.1445 24.1223 33.1445 24.899Z"-->
    <!--          fill="white" />-->
    <!--      <path-->
    <!--          d="M87 24.899C87 25.3254 86.8718 25.6529 86.6153 25.8813C86.3738 26.1097 86.14 26.2849 85.9136 26.4067C85.5817 26.6199 85.2121 26.7874 84.8047 26.9093C84.3974 27.0159 83.9673 27.0692 83.5147 27.0692H83.1752V24.899H75.5256V31.5921H83.1979V29.2622H86.9547V31.5237C86.9547 31.9349 86.8642 32.2927 86.6832 32.5972C86.502 32.9019 86.2532 33.1532 85.9363 33.3511C85.6346 33.5491 85.2724 33.7014 84.85 33.808C84.4275 33.8994 83.9824 33.9451 83.5147 33.9451H75.1182C74.6656 33.9451 74.2356 33.8917 73.8283 33.7852C73.4209 33.6633 73.0587 33.5034 72.7419 33.3054C72.4401 33.0922 72.1988 32.8409 72.0176 32.5516C71.8366 32.2622 71.7461 31.9349 71.7461 31.5693V24.8305C71.7461 24.4955 71.8366 24.1909 72.0176 23.9168C72.1988 23.6274 72.4401 23.3837 72.7419 23.1858C73.0587 22.9726 73.4209 22.805 73.8283 22.6832C74.2356 22.5614 74.6731 22.5005 75.1409 22.5005H83.5147C84.5708 22.5005 85.4158 22.7061 86.0495 23.1172C86.6832 23.5284 87 24.1223 87 24.899Z"-->
    <!--          fill="white" />-->
    <!--      <path-->
    <!--          d="M65.8786 33.9679V26.9181L61.3659 32.0452L56.8077 26.9181V33.9679H53.0207V22.5005H53.7464C53.9429 22.5005 54.1924 22.5081 54.4948 22.5234C54.8122 22.5234 55.1372 22.5691 55.4699 22.6607C55.8175 22.7522 56.1653 22.8743 56.5129 23.0269C56.8607 23.1795 57.1781 23.4084 57.4654 23.7136L61.3659 27.8336L65.221 23.7136C65.4931 23.4084 65.803 23.1795 66.1507 23.0269C66.4985 22.8743 66.8461 22.7522 67.1939 22.6607C67.5415 22.5691 67.8742 22.5234 68.1917 22.5234C68.5091 22.5081 68.7662 22.5005 68.9627 22.5005H69.6657V33.9679H65.8786Z"-->
    <!--          fill="white" />-->
    <!--    </svg>-->
  </NuxtLink>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
.logo {
  margin-right: 2rem;
  flex-shrink: 0;
  color: var(--accent-on-accent-secondary);

  img {
    height: 2.2rem;
  }

  svg {
    object-position: center;
    object-fit: contain;
    //position: relative;
    //bottom: 5px;
  }
}
</style>
