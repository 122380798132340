import mitt from 'mitt'

export type Events = {
    notify: {
        success: boolean
        msg: string
    }
    toggle_filters: {
        value: boolean
    }
    highlight_point: {
        value: string,
        highlighted: boolean
    }
    comp_search: string | null
    hide_tooltip: any
    update_tooltip_text: any
    show_tooltip: Record<any, any>
    highlight_line: Record<any, any>
    fetch_data: any
}

const emitter = mitt<Events>()

export const EventBus = {
    $on: emitter.on,
    $off: emitter.off,
    $emit: emitter.emit
}
