import {io, Socket} from "socket.io-client";

export default defineNuxtPlugin((nuxtApp) => {
    const socket = io('https://api.csmarketcap.com', {
        path: "/sockets",
        transports: ["websocket", "polling"],
        withCredentials: true,
    });

    nuxtApp.provide("socket", socket);
});

declare module "#app" {
    interface NuxtApp {
        $socket: Socket;
    }
}

declare module "vue" {
    interface ComponentCustomProperties {
        $socket: Socket;
    }
}
