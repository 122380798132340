export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type AddonFullType = {
  __typename?: 'AddonFullType';
  description: Scalars['String']['output'];
  is_active: Scalars['Boolean']['output'];
  logo_url: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type AddonsListResult = {
  __typename?: 'AddonsListResult';
  max_pages: Scalars['Int']['output'];
  result?: Maybe<Array<AddonFullType>>;
};

export type AdvancedSearchBody = {
  __typename?: 'AdvancedSearchBody';
  category?: Maybe<Scalars['String']['output']>;
  collection?: Maybe<Scalars['String']['output']>;
  colors?: Maybe<Array<Color>>;
  crates?: Maybe<Array<Maybe<CrateItem>>>;
  exterior?: Maybe<Scalars['String']['output']>;
  has_souvenir: Scalars['Boolean']['output'];
  has_stattrak: Scalars['Boolean']['output'];
  is_souvenir: Scalars['Boolean']['output'];
  is_stattrak: Scalars['Boolean']['output'];
  item_max_float?: Maybe<Scalars['Float']['output']>;
  item_min_float?: Maybe<Scalars['Float']['output']>;
  last_update?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  localed_rarity?: Maybe<Scalars['String']['output']>;
  localed_skin?: Maybe<Scalars['String']['output']>;
  market_hash_name: Scalars['String']['output'];
  markets?: Maybe<Scalars['Int']['output']>;
  max_float?: Maybe<Scalars['Float']['output']>;
  max_price?: Maybe<Scalars['Int']['output']>;
  min_float?: Maybe<Scalars['Float']['output']>;
  min_price?: Maybe<Scalars['Int']['output']>;
  offers?: Maybe<Scalars['Int']['output']>;
  phase?: Maybe<Scalars['String']['output']>;
  rarity?: Maybe<Scalars['String']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
  sales_1_day?: Maybe<Scalars['Int']['output']>;
  sales_7_days?: Maybe<Scalars['Int']['output']>;
  sales_30_days?: Maybe<Scalars['Int']['output']>;
  skin?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
};

export type AdvancedSearchInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  collection?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  colorGroup?: InputMaybe<ColorGroup>;
  exterior?: InputMaybe<Scalars['String']['input']>;
  extra?: InputMaybe<Extra>;
  groupBy?: InputMaybe<GroupBy>;
  hasSouvenir?: InputMaybe<Scalars['Boolean']['input']>;
  hasStattrak?: InputMaybe<Scalars['Boolean']['input']>;
  isSouvenir?: InputMaybe<Scalars['Boolean']['input']>;
  isStattrak?: InputMaybe<Scalars['Boolean']['input']>;
  itemMaxFloat?: InputMaybe<Scalars['Float']['input']>;
  itemMinFloat?: InputMaybe<Scalars['Float']['input']>;
  maxFloat?: InputMaybe<Scalars['Float']['input']>;
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
  minFloat?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Int']['input']>;
  orderBy: OrderBy;
  phase?: InputMaybe<Scalars['String']['input']>;
  rarity?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  stickerEffect?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  tournamentEvent?: InputMaybe<Scalars['String']['input']>;
  weapon?: InputMaybe<Scalars['String']['input']>;
};

export type AdvancedSearchInputV2 = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  collections?: InputMaybe<Array<Scalars['String']['input']>>;
  colorGroup?: InputMaybe<ColorGroup>;
  colors?: InputMaybe<Array<Scalars['String']['input']>>;
  exlcudeItemsWithLastRarity?: InputMaybe<Scalars['Boolean']['input']>;
  exteriors?: InputMaybe<Array<Scalars['String']['input']>>;
  groupBy?: InputMaybe<GroupBy>;
  hasSouvenir?: InputMaybe<Scalars['Boolean']['input']>;
  hasStattrak?: InputMaybe<Scalars['Boolean']['input']>;
  isSouvenir?: InputMaybe<Scalars['Boolean']['input']>;
  isStattrak?: InputMaybe<Scalars['Boolean']['input']>;
  itemMaxFloat?: InputMaybe<Scalars['Float']['input']>;
  itemMinFloat?: InputMaybe<Scalars['Float']['input']>;
  maxFloat?: InputMaybe<Scalars['Float']['input']>;
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
  minFloat?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Int']['input']>;
  orderBy: OrderBy;
  phases?: InputMaybe<Array<Scalars['String']['input']>>;
  rarities?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  stickerEffects?: InputMaybe<Array<Scalars['String']['input']>>;
  teams?: InputMaybe<Array<Scalars['String']['input']>>;
  tournamentEvents?: InputMaybe<Array<Scalars['String']['input']>>;
  weapons?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AdvancedSearchResult = {
  __typename?: 'AdvancedSearchResult';
  max_pages: Scalars['Int']['output'];
  result: Array<Maybe<AdvancedSearchBody>>;
};

export type AdvancedSearchType = {
  __typename?: 'AdvancedSearchType';
  category?: Maybe<Scalars['String']['output']>;
  collection?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  colorGroup?: Maybe<ColorGroup>;
  exterior?: Maybe<Scalars['String']['output']>;
  extra?: Maybe<Extra>;
  groupBy?: Maybe<GroupBy>;
  maxPrice?: Maybe<Scalars['Int']['output']>;
  minPrice?: Maybe<Scalars['Int']['output']>;
  orderBy: OrderBy;
  phase?: Maybe<Scalars['String']['output']>;
  rarity?: Maybe<Scalars['String']['output']>;
  searchText?: Maybe<Scalars['String']['output']>;
  stickerEffect?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  tournamentEvent?: Maybe<Scalars['String']['output']>;
  weapon?: Maybe<Scalars['String']['output']>;
};

export type AggregatedMaxOrderItem = {
  __typename?: 'AggregatedMaxOrderItem';
  market_hash_name: Scalars['String']['output'];
  orders: Array<Maybe<MaxOrder>>;
};

export type AggregatedMinPriceItem = {
  __typename?: 'AggregatedMinPriceItem';
  market_hash_name: Scalars['String']['output'];
  prices: Array<Maybe<MinPrice>>;
};

export type AvailableMarkets = {
  __typename?: 'AvailableMarkets';
  logo_name: Scalars['String']['output'];
  market_full_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
};

export type BlogCategory = {
  __typename?: 'BlogCategory';
  color: Scalars['String']['output'];
  created_at: Scalars['Date']['output'];
  data: Array<BlogCategoryDataType>;
  lang: Scalars['String']['output'];
  link: Scalars['String']['output'];
  posts_count?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at: Scalars['Date']['output'];
};

export type BlogCategoryDataInput = {
  lang: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type BlogCategoryDataType = {
  __typename?: 'BlogCategoryDataType';
  lang: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type BlogPost = {
  __typename?: 'BlogPost';
  author: UserInfo;
  category: BlogCategory;
  content: Scalars['String']['output'];
  created_at: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  hidden: Scalars['Boolean']['output'];
  img: Scalars['String']['output'];
  is_main: Scalars['Boolean']['output'];
  lang: Scalars['String']['output'];
  langs: Array<Maybe<Scalars['String']['output']>>;
  last_edited_by: UserInfo;
  liked?: Maybe<Scalars['Date']['output']>;
  likes_count: Scalars['String']['output'];
  link: Scalars['String']['output'];
  published_at: Scalars['Date']['output'];
  read_time: Scalars['Int']['output'];
  tags: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['Date']['output'];
  viewed?: Maybe<Scalars['Date']['output']>;
  views: Scalars['String']['output'];
};

export type BuyOrdersItemData = {
  __typename?: 'BuyOrdersItemData';
  average_buy_price_90d?: Maybe<Scalars['Float']['output']>;
  buy_orders_last_7d?: Maybe<Scalars['Int']['output']>;
  buy_orders_last_24h?: Maybe<Scalars['Int']['output']>;
  latest_buy_order_price?: Maybe<Scalars['Float']['output']>;
  max_buy_order_price?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_7d?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_24h?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_30d?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_90d?: Maybe<Scalars['Int']['output']>;
  median_buy_price_90d?: Maybe<Scalars['Float']['output']>;
  min_buy_order_price?: Maybe<Scalars['Int']['output']>;
};

export enum BuyRequestPlan {
  ApiRequestPlan_1 = 'API_REQUEST_PLAN_1',
  ApiRequestPlan_2 = 'API_REQUEST_PLAN_2',
  ApiRequestPlan_3 = 'API_REQUEST_PLAN_3'
}

export type Category = {
  __typename?: 'Category';
  category: Scalars['String']['output'];
  items: Array<Maybe<Item>>;
};

export enum CollectionGroup {
  Agent = 'AGENT',
  Graffiti = 'GRAFFITI',
  Patch = 'PATCH',
  Sticker = 'STICKER',
  Weapon = 'WEAPON'
}

export type CollectionInfo = {
  __typename?: 'CollectionInfo';
  group: CollectionGroup;
  link_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export enum CollectionYearSort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Color = {
  __typename?: 'Color';
  hex: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export type ColorClear = {
  __typename?: 'ColorClear';
  hex: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum ColorGroup {
  Beige = 'BEIGE',
  Black = 'BLACK',
  Blue = 'BLUE',
  Brown = 'BROWN',
  Gray = 'GRAY',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Purple = 'PURPLE',
  Red = 'RED',
  Violet = 'VIOLET',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export type ColorItemCount = {
  __typename?: 'ColorItemCount';
  count: Scalars['Int']['output'];
  hex: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CompareItem = {
  __typename?: 'CompareItem';
  exterior?: Maybe<CompareItemExterior>;
  is_souvenir: Scalars['Boolean']['output'];
  is_stattrak: Scalars['Boolean']['output'];
  liked?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  localed_rarity?: Maybe<Scalars['String']['output']>;
  localed_skin?: Maybe<Scalars['String']['output']>;
  market_hash_name: Scalars['String']['output'];
  max_price: ComparisonPriceInfo;
  min_price: ComparisonPriceInfo;
  phase?: Maybe<Scalars['String']['output']>;
  profit: ComparisonProfit;
  rarity?: Maybe<Scalars['String']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
  sales: Scalars['Int']['output'];
  steam_price?: Maybe<ComparisonPriceInfo>;
  weapon?: Maybe<Scalars['String']['output']>;
};

export type CompareItemExterior = {
  __typename?: 'CompareItemExterior';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CompareOutput = {
  __typename?: 'CompareOutput';
  items: Array<Maybe<CompareItem>>;
  settings: ComparePageSettings;
};

export type ComparePageSettings = {
  __typename?: 'ComparePageSettings';
  hasNextPage: Scalars['Boolean']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  page: Scalars['Int']['output'];
  sort?: Maybe<ComparisonSort>;
  totalCount: Scalars['Int']['output'];
};

export type ComparisonFiltersCategoryData = {
  __typename?: 'ComparisonFiltersCategoryData';
  id: SkinCategory;
  title: Scalars['String']['output'];
};

export type ComparisonFiltersData = {
  __typename?: 'ComparisonFiltersData';
  categories: Array<Maybe<ComparisonFiltersCategoryData>>;
  collections: Array<Maybe<Scalars['String']['output']>>;
  exteriors: Array<Maybe<Scalars['String']['output']>>;
  markets: Array<Maybe<AvailableMarkets>>;
  rarityGroups: Array<Maybe<ComparisonFiltersRarityGroupData>>;
  types: Array<Maybe<Category>>;
};

export type ComparisonFiltersInput = {
  categories?: InputMaybe<Array<InputMaybe<SkinCategory>>>;
  collections?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exteriors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  liked?: InputMaybe<Scalars['Boolean']['input']>;
  markets_from?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  markets_to?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  offers?: InputMaybe<Scalars['Int']['input']>;
  price_from?: InputMaybe<Scalars['Int']['input']>;
  price_to?: InputMaybe<Scalars['Int']['input']>;
  profit_percentage_from?: InputMaybe<Scalars['Float']['input']>;
  profit_percentage_to?: InputMaybe<Scalars['Int']['input']>;
  profit_value_from?: InputMaybe<Scalars['Int']['input']>;
  profit_value_to?: InputMaybe<Scalars['Int']['input']>;
  rarities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sales?: InputMaybe<Scalars['Int']['input']>;
  sales_period: SalesPeriod;
  search?: InputMaybe<Scalars['String']['input']>;
  steam_price_from?: InputMaybe<Scalars['Int']['input']>;
  steam_price_to?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComparisonFiltersRarityData = {
  __typename?: 'ComparisonFiltersRarityData';
  rarity: Scalars['String']['output'];
  rarity_color: Scalars['String']['output'];
};

export type ComparisonFiltersRarityGroupData = {
  __typename?: 'ComparisonFiltersRarityGroupData';
  rarities: Array<Maybe<ComparisonFiltersRarityData>>;
  title: Scalars['String']['output'];
};

export type ComparisonInput = {
  filter: ComparisonFiltersInput;
  lang?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  sort: ComparisonSort;
};

export type ComparisonMarket = {
  __typename?: 'ComparisonMarket';
  market: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type ComparisonMarketInfo = {
  __typename?: 'ComparisonMarketInfo';
  average_price: Scalars['Int']['output'];
  max_price: Scalars['Int']['output'];
  min_price: Scalars['Int']['output'];
  offers: Array<Maybe<ComparisonPriceInfo>>;
  sales: Scalars['Int']['output'];
};

export type ComparisonMarketsInput = {
  link: Scalars['String']['input'];
  markets_from?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  markets_to?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  offers?: InputMaybe<Scalars['Int']['input']>;
  price_from?: InputMaybe<Scalars['Int']['input']>;
  price_to?: InputMaybe<Scalars['Int']['input']>;
  sales_period: SalesPeriod;
};

export type ComparisonPriceInfo = {
  __typename?: 'ComparisonPriceInfo';
  market: ComparisonPriceMarket;
  price: Scalars['Int']['output'];
};

export type ComparisonPriceMarket = {
  __typename?: 'ComparisonPriceMarket';
  logo_name: Scalars['String']['output'];
  market_full_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
};

export type ComparisonProfit = {
  __typename?: 'ComparisonProfit';
  percentage: Scalars['Float']['output'];
  value: Scalars['Int']['output'];
};

export enum ComparisonSort {
  MaxPriceAsc = 'MAX_PRICE_ASC',
  MaxPriceDesc = 'MAX_PRICE_DESC',
  MinPriceAsc = 'MIN_PRICE_ASC',
  MinPriceDesc = 'MIN_PRICE_DESC',
  ProfitPercentageAsc = 'PROFIT_PERCENTAGE_ASC',
  ProfitPercentageDesc = 'PROFIT_PERCENTAGE_DESC',
  ProfitValueAsc = 'PROFIT_VALUE_ASC',
  ProfitValueDesc = 'PROFIT_VALUE_DESC',
  SalesAsc = 'SALES_ASC',
  SalesDesc = 'SALES_DESC',
  SteamPriceAsc = 'STEAM_PRICE_ASC',
  SteamPriceDesc = 'STEAM_PRICE_DESC'
}

export type ContainerItem = {
  __typename?: 'ContainerItem';
  link: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
};

export type ContractItemMarketInfo = {
  __typename?: 'ContractItemMarketInfo';
  logo_name: Scalars['String']['output'];
  market_full_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
};

export type ContractItemMarketVolume = {
  __typename?: 'ContractItemMarketVolume';
  full_name: Scalars['String']['output'];
  link: Scalars['String']['output'];
  market: Scalars['String']['output'];
  min_price: Scalars['Float']['output'];
};

export type CrateItem = {
  __typename?: 'CrateItem';
  link: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
};

export type CratesItem = {
  __typename?: 'CratesItem';
  link: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
};

export type CreateBlogCategoryInput = {
  color: Scalars['String']['input'];
  data: Array<BlogCategoryDataInput>;
};

export type CreateBlogPostInput = {
  category: Scalars['String']['input'];
  content: Scalars['String']['input'];
  description: Scalars['String']['input'];
  hidden: Scalars['Boolean']['input'];
  img: Scalars['String']['input'];
  is_main: Scalars['Boolean']['input'];
  published_at?: InputMaybe<Scalars['Date']['input']>;
  tags: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateDynamicPageInput = {
  advanced_search_parameters: AdvancedSearchInput;
  image_url?: InputMaybe<Scalars['String']['input']>;
  locals: Array<DynamicPageLocal>;
  slug: Scalars['String']['input'];
};

export type CreateMarketInput = {
  apps: Array<InputMaybe<MarketInfoAppInput>>;
  foundation_date: Scalars['Date']['input'];
  is_active: Scalars['Boolean']['input'];
  locals: Array<InputMaybe<MarketInfoLocalInput>>;
  logo_name: Scalars['String']['input'];
  market_full_name: Scalars['String']['input'];
  market_name: Scalars['String']['input'];
  market_type: Scalars['String']['input'];
  payment_methods: Array<InputMaybe<MarketInfoPaymentMethodInput>>;
  payout_methods: Array<InputMaybe<MarketInfoPayoutMethodInput>>;
  rating: Scalars['Float']['input'];
  registered_company: Scalars['String']['input'];
  seller_fee: Scalars['Float']['input'];
  social_media: MarketInfoSocialMediaInput;
  trust_pilot_link: Scalars['String']['input'];
  votes: Scalars['Float']['input'];
  website_url: Scalars['String']['input'];
};

export type DailyChart = {
  __typename?: 'DailyChart';
  avg_price: Scalars['Int']['output'];
  date: Scalars['String']['output'];
  max_price: Scalars['Int']['output'];
  min_price: Scalars['Int']['output'];
  total_count: Scalars['String']['output'];
  total_value: Scalars['String']['output'];
};

export type DailyPriceChart = {
  __typename?: 'DailyPriceChart';
  date: Scalars['String']['output'];
  min_price: Scalars['Int']['output'];
};

export type Day = {
  __typename?: 'Day';
  date: Scalars['String']['output'];
  total_count: Scalars['String']['output'];
  total_value: Scalars['String']['output'];
};

export type DeleteBlogCategoryInput = {
  link: Scalars['String']['input'];
};

export type DeleteBlogPostInput = {
  lang: Scalars['String']['input'];
  link: Scalars['String']['input'];
};

export type Description = {
  __typename?: 'Description';
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DynamicPageBaseInfo = {
  __typename?: 'DynamicPageBaseInfo';
  slug: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type DynamicPageFullInfo = {
  __typename?: 'DynamicPageFullInfo';
  advancedSearchParameters: AdvancedSearchType;
  image_url?: Maybe<Scalars['String']['output']>;
  locals?: Maybe<Array<DynamicPageLocalType>>;
  slug: Scalars['String']['output'];
};

export type DynamicPageFullInfoLocal = {
  __typename?: 'DynamicPageFullInfoLocal';
  seo_tags: DynamicPageLocalSeoData;
  user_description: Scalars['String']['output'];
  user_text: Scalars['String']['output'];
};

export type DynamicPageInfo = {
  __typename?: 'DynamicPageInfo';
  all?: Maybe<Array<Maybe<DynamicPageBaseInfo>>>;
  data: AdvancedSearchResult;
  info?: Maybe<DynamicPageFullInfo>;
};


export type DynamicPageInfoAllArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type DynamicPageInfoDataArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  slug: Scalars['String']['input'];
};


export type DynamicPageInfoInfoArgs = {
  slug: Scalars['String']['input'];
};

export type DynamicPageLocal = {
  local: Scalars['String']['input'];
  seo_tags: DynamicPageLocalSeoDataInput;
  user_description: Scalars['String']['input'];
  user_text: Scalars['String']['input'];
};

export type DynamicPageLocalSeoData = {
  __typename?: 'DynamicPageLocalSeoData';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DynamicPageLocalSeoDataInput = {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type DynamicPageLocalSeoDataType = {
  __typename?: 'DynamicPageLocalSeoDataType';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DynamicPageLocalType = {
  __typename?: 'DynamicPageLocalType';
  local: Scalars['String']['output'];
  seo_tags: DynamicPageLocalSeoDataType;
  user_description: Scalars['String']['output'];
  user_text: Scalars['String']['output'];
};

export type Exterior = {
  __typename?: 'Exterior';
  exterior?: Maybe<Scalars['String']['output']>;
  extra_link?: Maybe<Scalars['String']['output']>;
  extra_name?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum Extra {
  HasSouvenir = 'HAS_SOUVENIR',
  HasStattrak = 'HAS_STATTRAK',
  NoSouvenir = 'NO_SOUVENIR',
  NoStattrak = 'NO_STATTRAK'
}

export enum ExtraWeaponPage {
  Normal = 'NORMAL',
  Souvenir = 'SOUVENIR',
  Stattrak = 'STATTRAK'
}

export type FamilySimilarSkins = {
  __typename?: 'FamilySimilarSkins';
  link: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
};

export type Gear = {
  __typename?: 'Gear';
  id: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  link: Scalars['String']['output'];
  section: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type GetBlogCategoriesInput = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type GetBlogCategoryInput = {
  lang?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
};

export type GetBlogPostInput = {
  lang?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
};

export type GetBlogPostsOutput = {
  __typename?: 'GetBlogPostsOutput';
  data: Array<Maybe<BlogPost>>;
  max_pages: Scalars['Int']['output'];
};

export type GetBlogPostsPageParamsInput = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type GetBlogPostsSearchParamsInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  is_main?: InputMaybe<Scalars['Boolean']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  liked?: InputMaybe<Scalars['Boolean']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetItemRatingResult = {
  __typename?: 'GetItemRatingResult';
  rating: Scalars['Float']['output'];
  votes: Scalars['Int']['output'];
};

export type GetMainBlogPostInput = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type GetUserInventoriesInput = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  limit: Scalars['Int']['input'];
  market: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  sort: GetUserInventoriesInputSort;
};

export enum GetUserInventoriesInputSort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type GetUserInventoriesOutput = {
  __typename?: 'GetUserInventoriesOutput';
  data: Array<Maybe<GetUserInventoriesUserOutput>>;
  max_pages: Scalars['Int']['output'];
  total_count: Scalars['Int']['output'];
};

export type GetUserInventoriesUserOutput = {
  __typename?: 'GetUserInventoriesUserOutput';
  avatar_url: Scalars['String']['output'];
  items_count: Scalars['Int']['output'];
  persona_name: Scalars['String']['output'];
  profile_url: Scalars['String']['output'];
  steam64: Scalars['String']['output'];
  total_value: Scalars['String']['output'];
};

export type GraffityType = {
  __typename?: 'GraffityType';
  color?: Maybe<Scalars['String']['output']>;
  link_name?: Maybe<Scalars['String']['output']>;
};

export enum GroupBy {
  ItemName = 'ITEM_NAME',
  ItemNameStattrackSouvenier = 'ITEM_NAME_STATTRACK_SOUVENIER',
  StattrackSouvenier = 'STATTRACK_SOUVENIER',
  Type = 'TYPE'
}

export type HighlightedProTeam = {
  __typename?: 'HighlightedProTeam';
  description?: Maybe<Scalars['String']['output']>;
  gears: Array<Maybe<Gear>>;
  highlighted_title?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  link?: Maybe<Scalars['String']['output']>;
  logo: Scalars['String']['output'];
  players: Array<Maybe<ProPlayer>>;
  social?: Maybe<ProPlayerSocial>;
  title: Scalars['String']['output'];
};

export type InventoriesCommonInfo = {
  __typename?: 'InventoriesCommonInfo';
  avg_items_count: Scalars['Int']['output'];
  avg_value: Scalars['Float']['output'];
  total_value: Scalars['String']['output'];
  unique_inventories: Scalars['String']['output'];
};

export type InventoryRarePopularItem = {
  __typename?: 'InventoryRarePopularItem';
  img_link_name: Scalars['String']['output'];
  item_name: Scalars['String']['output'];
  link_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  rarity?: Maybe<Scalars['String']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
};

export type InventoryValueChart = {
  __typename?: 'InventoryValueChart';
  end_date: Scalars['Date']['output'];
  market_name: Scalars['String']['output'];
  start_date: Scalars['Date']['output'];
  total_count: Scalars['Int']['output'];
  total_value: Scalars['String']['output'];
};

export enum InventoryValueChartPeriod {
  All = 'ALL',
  Month = 'MONTH',
  ThreeMonth = 'THREE_MONTH',
  Week = 'WEEK'
}

export type Item = {
  __typename?: 'Item';
  full_name: Scalars['String']['output'];
  weapon: Scalars['String']['output'];
};

export type ItemColorGroupResult = {
  __typename?: 'ItemColorGroupResult';
  containedColors: Array<ColorClear>;
  group: ColorGroup;
};

export type ItemCountByColor = {
  __typename?: 'ItemCountByColor';
  count: Scalars['Int']['output'];
  hex: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ItemData = {
  __typename?: 'ItemData';
  commodity_id?: Maybe<Scalars['String']['output']>;
  exterior?: Maybe<Scalars['String']['output']>;
  extra?: Maybe<Scalars['String']['output']>;
  market_hash_name: Scalars['String']['output'];
  phase?: Maybe<Scalars['String']['output']>;
  rarity?: Maybe<Scalars['String']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
  skin?: Maybe<Scalars['String']['output']>;
  weapon?: Maybe<Scalars['String']['output']>;
};

export type ItemDesigner = {
  __typename?: 'ItemDesigner';
  designer_name: Scalars['String']['output'];
  designer_profile_url: Scalars['String']['output'];
};

export type ItemMarketInfo = {
  __typename?: 'ItemMarketInfo';
  logo_name: Scalars['String']['output'];
  market_full_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  trust_pilot_link?: Maybe<Scalars['String']['output']>;
  votes: Scalars['Int']['output'];
};

export type ItemPageInfo = {
  __typename?: 'ItemPageInfo';
  available_markets?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  category: Scalars['String']['output'];
  chart?: Maybe<Array<Maybe<WeeklyChart>>>;
  collection?: Maybe<Scalars['String']['output']>;
  colorItemCount: Array<Maybe<ColorItemCount>>;
  colors?: Maybe<Array<Maybe<Color>>>;
  contains?: Maybe<Array<Maybe<ContainerItem>>>;
  crates?: Maybe<Array<Maybe<CratesItem>>>;
  current_price?: Maybe<Scalars['Int']['output']>;
  descriptions?: Maybe<Array<Maybe<Description>>>;
  designer_link?: Maybe<Scalars['String']['output']>;
  designer_name?: Maybe<Scalars['String']['output']>;
  designers?: Maybe<Array<Maybe<ItemDesigner>>>;
  exterior?: Maybe<Scalars['String']['output']>;
  exteriors?: Maybe<Array<Maybe<Exterior>>>;
  family_skins?: Maybe<Array<Maybe<FamilySimilarSkins>>>;
  graffityTypes?: Maybe<Array<GraffityType>>;
  id: Scalars['Int']['output'];
  is_souvenir: Scalars['Boolean']['output'];
  is_stattrak: Scalars['Boolean']['output'];
  item_max_float?: Maybe<Scalars['Float']['output']>;
  item_min_float?: Maybe<Scalars['Float']['output']>;
  last_update?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  locals: Locals;
  market_hash_name: Scalars['String']['output'];
  markets_info?: Maybe<Array<Maybe<ItemMarketInfo>>>;
  markets_offers?: Maybe<Array<Maybe<MarketOffer>>>;
  max_float?: Maybe<Scalars['Float']['output']>;
  min_float?: Maybe<Scalars['Float']['output']>;
  phase?: Maybe<Scalars['String']['output']>;
  phases?: Maybe<Array<Maybe<PhaseItem>>>;
  popularity?: Maybe<Scalars['String']['output']>;
  price_statistics?: Maybe<PriceStatistics>;
  pro_players: Array<Maybe<ItemPageProPlayer>>;
  rarity_color?: Maybe<Scalars['String']['output']>;
  rating: GetItemRatingResult;
  seven_days?: Maybe<Array<Maybe<DailyChart>>>;
  similar_skins?: Maybe<Array<Maybe<FamilySimilarSkins>>>;
  skin?: Maybe<Scalars['String']['output']>;
  sticker_effect?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  tournament_event?: Maybe<Scalars['String']['output']>;
  update_link?: Maybe<Scalars['String']['output']>;
  update_text?: Maybe<Scalars['String']['output']>;
  userVote?: Maybe<Scalars['Int']['output']>;
  volume?: Maybe<Array<Maybe<Volume>>>;
  weapon?: Maybe<Scalars['String']['output']>;
  workshop_link?: Maybe<Scalars['String']['output']>;
  workshop_url?: Maybe<Scalars['String']['output']>;
};

export type ItemPageProPlayer = {
  __typename?: 'ItemPageProPlayer';
  avatar?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  nick_name: Scalars['String']['output'];
};

export type ItemStatsByPeriod = {
  __typename?: 'ItemStatsByPeriod';
  daily?: Maybe<Array<Maybe<DailyChart>>>;
  weekly?: Maybe<Array<Maybe<WeeklyChart>>>;
};

export type ItemWeaponPageFiltersResult = {
  __typename?: 'ItemWeaponPageFiltersResult';
  normal_count?: Maybe<Scalars['Int']['output']>;
  rarities_count?: Maybe<Array<Maybe<RaritiesCount>>>;
  souvenir_count?: Maybe<Scalars['Int']['output']>;
  stattrak_count?: Maybe<Scalars['Int']['output']>;
  teams?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ItemsByField = {
  __typename?: 'ItemsByField';
  cases?: Maybe<Array<Maybe<CrateItem>>>;
  category: Scalars['String']['output'];
  collection?: Maybe<Scalars['String']['output']>;
  colors?: Maybe<Array<Maybe<Color>>>;
  extra_range?: Maybe<PriceRange>;
  has_souvenir: Scalars['Boolean']['output'];
  has_stattrak: Scalars['Boolean']['output'];
  is_music_kit_box: Scalars['Boolean']['output'];
  item_max_float?: Maybe<Scalars['Float']['output']>;
  item_min_float?: Maybe<Scalars['Float']['output']>;
  link: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  markets_with_offers?: Maybe<Scalars['Int']['output']>;
  max_float?: Maybe<Scalars['Float']['output']>;
  min_float?: Maybe<Scalars['Float']['output']>;
  offers?: Maybe<Scalars['Int']['output']>;
  phase?: Maybe<Scalars['String']['output']>;
  price_range?: Maybe<PriceRange>;
  rarity?: Maybe<Scalars['String']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Float']['output'];
  skin?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  votes: Scalars['Int']['output'];
  weapon?: Maybe<Scalars['String']['output']>;
};

export type ItemsByFieldResult = {
  __typename?: 'ItemsByFieldResult';
  max_pages: Scalars['Int']['output'];
  result: Array<Maybe<ItemsByField>>;
};

export type ItemsContainerPageResult = {
  __typename?: 'ItemsContainerPageResult';
  description?: Maybe<Scalars['String']['output']>;
  gloves: Array<Maybe<Scalars['String']['output']>>;
  is_collection: Scalars['Boolean']['output'];
  knives: Array<Maybe<Scalars['String']['output']>>;
  localed_name: Scalars['String']['output'];
  normal: Array<Maybe<Scalars['String']['output']>>;
  result: Array<Maybe<ItemsByField>>;
};

export type ItemsPriceDiffs = {
  __typename?: 'ItemsPriceDiffs';
  exterior?: Maybe<Scalars['String']['output']>;
  is_souvenir: Scalars['Boolean']['output'];
  is_stattrak: Scalars['Boolean']['output'];
  last_update?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  localed_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  phase?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  price_difference: Scalars['Float']['output'];
  rarity_color?: Maybe<Scalars['String']['output']>;
  skin?: Maybe<Scalars['String']['output']>;
};

export type ItemsSteamVolumeStatisticResponse = {
  __typename?: 'ItemsSteamVolumeStatisticResponse';
  skins: Array<ItemsSteamVolumeStatisticSkin>;
  total_record_count: Scalars['Int']['output'];
  total_volume_usd: Scalars['Float']['output'];
  updated_at: Scalars['Date']['output'];
};

export type ItemsSteamVolumeStatisticSkin = {
  __typename?: 'ItemsSteamVolumeStatisticSkin';
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  seven_day_chart?: Maybe<Array<Maybe<ItemsSteamVolumeStatisticSkinChart>>>;
  total_usd: Scalars['Float']['output'];
};

export type ItemsSteamVolumeStatisticSkinChart = {
  __typename?: 'ItemsSteamVolumeStatisticSkinChart';
  end_date: Scalars['Date']['output'];
  start_date: Scalars['Date']['output'];
  total_quantity: Scalars['Int']['output'];
  total_sales: Scalars['Int']['output'];
};

export type LatestManualCheckedInventory = {
  __typename?: 'LatestManualCheckedInventory';
  checks: Array<Maybe<LatestManualCheckedInventoryItem>>;
  count: Scalars['Int']['output'];
};

export type LatestManualCheckedInventoryItem = {
  __typename?: 'LatestManualCheckedInventoryItem';
  avatar_url: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  inventory_value: Scalars['String']['output'];
  persona_name: Scalars['String']['output'];
  steamid64: Scalars['String']['output'];
};

export type LikeBlogPostInput = {
  link: Scalars['String']['input'];
};

export type ListingsItemData = {
  __typename?: 'ListingsItemData';
  average_sell_price_90d?: Maybe<Scalars['Float']['output']>;
  latest_sell_order_price?: Maybe<Scalars['Float']['output']>;
  max_sell_order_price?: Maybe<Scalars['Int']['output']>;
  median_sell_price_90d?: Maybe<Scalars['Float']['output']>;
  min_sell_order_price?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_7d?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_24h?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_30d?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_90d?: Maybe<Scalars['Int']['output']>;
  sell_orders_last_7d?: Maybe<Scalars['Int']['output']>;
  sell_orders_last_24h?: Maybe<Scalars['Int']['output']>;
};

export type Locals = {
  __typename?: 'Locals';
  description?: Maybe<Scalars['String']['output']>;
  localed_collection?: Maybe<Scalars['String']['output']>;
  localed_name: Scalars['String']['output'];
  pattern?: Maybe<Scalars['String']['output']>;
  rarity?: Maybe<Scalars['String']['output']>;
  skin?: Maybe<Scalars['String']['output']>;
};

export type MakeVoteInput = {
  marketHashName: Scalars['String']['input'];
  vote: Scalars['Int']['input'];
};

export type MakeVoteResult = {
  __typename?: 'MakeVoteResult';
  market_hash_name: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  votes: Scalars['Int']['output'];
};

export type MarketAdminApp = {
  __typename?: 'MarketAdminApp';
  link?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MarketAdminCountry = {
  __typename?: 'MarketAdminCountry';
  country?: Maybe<Scalars['String']['output']>;
  flag?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
};

export type MarketAdminCountryDetails = {
  __typename?: 'MarketAdminCountryDetails';
  code: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  flag?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
};

export type MarketAdminDescription = {
  __typename?: 'MarketAdminDescription';
  icon_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MarketAdminLocaleDetails = {
  __typename?: 'MarketAdminLocaleDetails';
  descriptions?: Maybe<Array<Maybe<MarketAdminDescription>>>;
  headquarters?: Maybe<Scalars['String']['output']>;
  key_people?: Maybe<Scalars['String']['output']>;
  local: Scalars['String']['output'];
  question_answers?: Maybe<Array<Maybe<MarketAdminQa>>>;
  summary?: Maybe<Scalars['String']['output']>;
  top_countries?: Maybe<Array<Maybe<MarketAdminCountryDetails>>>;
};

export type MarketAdminPaymentMethod = {
  __typename?: 'MarketAdminPaymentMethod';
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MarketAdminPayoutMethod = {
  __typename?: 'MarketAdminPayoutMethod';
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MarketAdminQa = {
  __typename?: 'MarketAdminQA';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MarketAdminResponse = {
  __typename?: 'MarketAdminResponse';
  apps?: Maybe<Array<Maybe<MarketAdminApp>>>;
  avg_discount?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  foundation_date?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Scalars['String']['output']>;
  items_percentage?: Maybe<Scalars['String']['output']>;
  locals?: Maybe<Array<Maybe<MarketAdminLocaleDetails>>>;
  logo_name?: Maybe<Scalars['String']['output']>;
  market_full_name?: Maybe<Scalars['String']['output']>;
  market_name?: Maybe<Scalars['String']['output']>;
  market_type?: Maybe<Scalars['String']['output']>;
  monthly_visit?: Maybe<Scalars['String']['output']>;
  offers?: Maybe<Scalars['String']['output']>;
  payment_methods?: Maybe<Array<Maybe<MarketAdminPaymentMethod>>>;
  payout_methods?: Maybe<Array<Maybe<MarketAdminPayoutMethod>>>;
  price_sum?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  registered_company?: Maybe<Scalars['String']['output']>;
  seller_fee?: Maybe<Scalars['Float']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  social_media?: Maybe<MarketAdminSocialMedia>;
  supported_games?: Maybe<Array<Maybe<MarketFullInfoSupportedGame>>>;
  trust_pilot_link?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  votes?: Maybe<Scalars['Float']['output']>;
  website_url?: Maybe<Scalars['String']['output']>;
};

export type MarketAdminSocialMedia = {
  __typename?: 'MarketAdminSocialMedia';
  discord?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  steam?: Maybe<Scalars['String']['output']>;
  telegram?: Maybe<Scalars['String']['output']>;
  twitch?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  vk?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type MarketFullInfo = {
  __typename?: 'MarketFullInfo';
  alternatives: Array<Maybe<MarketInfo>>;
  apps: Array<Maybe<MarketFullInfoApp>>;
  chart: Array<Maybe<MarketStatisticChart>>;
  descriptions?: Maybe<Array<Maybe<MarketFullInfoDescription>>>;
  foundation_date?: Maybe<Scalars['Date']['output']>;
  headquarters?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_active: Scalars['Boolean']['output'];
  items_percentage: Scalars['Float']['output'];
  key_people?: Maybe<Scalars['String']['output']>;
  logo_name?: Maybe<Scalars['String']['output']>;
  market_full_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
  market_type?: Maybe<Scalars['String']['output']>;
  monthly_visit?: Maybe<Scalars['Int']['output']>;
  payment_methods?: Maybe<Array<Maybe<MarketFullInfoPaymentMethod>>>;
  payout_methods?: Maybe<Array<Maybe<MarketFullInfoPayoutMethod>>>;
  question_answers?: Maybe<Array<Maybe<MarketFullInfoQa>>>;
  rating: Scalars['Float']['output'];
  registered_company?: Maybe<Scalars['String']['output']>;
  seller_fee?: Maybe<Scalars['Float']['output']>;
  slug: Scalars['String']['output'];
  social_media?: Maybe<MarketFullInfoSocialMedia>;
  summary?: Maybe<Scalars['String']['output']>;
  supply_statistic: MarketFullSupplyStatistics;
  supported_games: Array<Maybe<MarketFullInfoSupportedGame>>;
  top_countries?: Maybe<Array<Maybe<MarketFullInfoTopCountry>>>;
  trust_pilot_link?: Maybe<Scalars['String']['output']>;
  votes?: Maybe<Scalars['Float']['output']>;
  website_url?: Maybe<Scalars['String']['output']>;
};


export type MarketFullInfoChartArgs = {
  period: Period;
};

export type MarketFullInfoApp = {
  __typename?: 'MarketFullInfoApp';
  link: Scalars['String']['output'];
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: MarketInfoAppType;
};

export type MarketFullInfoDescription = {
  __typename?: 'MarketFullInfoDescription';
  icon_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MarketFullInfoPaymentMethod = {
  __typename?: 'MarketFullInfoPaymentMethod';
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MarketFullInfoPayoutMethod = {
  __typename?: 'MarketFullInfoPayoutMethod';
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MarketFullInfoQa = {
  __typename?: 'MarketFullInfoQA';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MarketFullInfoSocialMedia = {
  __typename?: 'MarketFullInfoSocialMedia';
  discord?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  steam?: Maybe<Scalars['String']['output']>;
  telegram?: Maybe<Scalars['String']['output']>;
  twitch?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  vk?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type MarketFullInfoSupportedGame = {
  __typename?: 'MarketFullInfoSupportedGame';
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MarketFullInfoTopCountry = {
  __typename?: 'MarketFullInfoTopCountry';
  country: Scalars['String']['output'];
  flag: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

export type MarketFullSupplyStatistics = {
  __typename?: 'MarketFullSupplyStatistics';
  avg_discount?: Maybe<Scalars['Float']['output']>;
  items?: Maybe<Scalars['Int']['output']>;
  offers?: Maybe<Scalars['Int']['output']>;
  price_sum?: Maybe<Scalars['Float']['output']>;
};

export type MarketInfo = {
  __typename?: 'MarketInfo';
  avg_discount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  items?: Maybe<Scalars['Int']['output']>;
  logo_name?: Maybe<Scalars['String']['output']>;
  market_full_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
  market_type?: Maybe<Scalars['String']['output']>;
  monthly_visit?: Maybe<Scalars['Int']['output']>;
  offers?: Maybe<Scalars['Int']['output']>;
  price_sum?: Maybe<Scalars['Float']['output']>;
  rating: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
  votes?: Maybe<Scalars['Float']['output']>;
};

export type MarketInfoAppInput = {
  link: Scalars['String']['input'];
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: MarketInfoAppType;
};

export enum MarketInfoAppType {
  Desktop = 'DESKTOP',
  Extention = 'EXTENTION',
  Mobile = 'MOBILE'
}

export type MarketInfoLocalDescriptionInput = {
  icon_name: Scalars['String']['input'];
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MarketInfoLocalInput = {
  descriptions: Array<InputMaybe<MarketInfoLocalDescriptionInput>>;
  headquarters: Scalars['String']['input'];
  key_people: Scalars['String']['input'];
  local: Scalars['String']['input'];
  question_answers: Array<InputMaybe<MarketInfoLocalQaInput>>;
  summary: Scalars['String']['input'];
  top_countries: Array<InputMaybe<MarketInfoLocalTopCountryInfo>>;
};

export type MarketInfoLocalQaInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MarketInfoLocalTopCountryInfo = {
  code: Scalars['String']['input'];
  country: Scalars['String']['input'];
  flag: Scalars['String']['input'];
};

export type MarketInfoPaymentMethodInput = {
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MarketInfoPayoutMethodInput = {
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MarketInfoSocialMediaInput = {
  discord?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  steam?: InputMaybe<Scalars['String']['input']>;
  telegram?: InputMaybe<Scalars['String']['input']>;
  twitch?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  vk?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type MarketListing = {
  __typename?: 'MarketListing';
  market: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  min_price: Scalars['Int']['output'];
  offers: Scalars['Int']['output'];
};

export type MarketOffer = {
  __typename?: 'MarketOffer';
  last_update: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
  offers: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  price_locked?: Maybe<Scalars['Int']['output']>;
};

export enum MarketOrderByCol {
  AvgDiscount = 'AVG_DISCOUNT',
  ItemsValue = 'ITEMS_VALUE',
  MonthlyVisit = 'MONTHLY_VISIT',
  Offers = 'OFFERS',
  Rating = 'RATING',
  UniqueItems = 'UNIQUE_ITEMS'
}

export type MarketOrderByInput = {
  col: MarketOrderByCol;
  order: MarketOrderByOrder;
};

export enum MarketOrderByOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type MarketStat = {
  __typename?: 'MarketStat';
  days: Array<Maybe<Day>>;
  market_full_name: Scalars['String']['output'];
  market_logo_name: Scalars['String']['output'];
  market_name: Scalars['String']['output'];
  seven_days_sales_value?: Maybe<Scalars['String']['output']>;
  seven_days_value_diff?: Maybe<Scalars['String']['output']>;
  today_count_diff?: Maybe<Scalars['String']['output']>;
  today_sales_count: Scalars['String']['output'];
  today_sales_value: Scalars['String']['output'];
  today_value_diff?: Maybe<Scalars['String']['output']>;
};

export type MarketStatForPeriod = {
  __typename?: 'MarketStatForPeriod';
  all_sales: Scalars['Int']['output'];
  avg_sales: Scalars['Int']['output'];
  days?: Maybe<Array<Maybe<Day>>>;
  max_sales: Scalars['Int']['output'];
  min_sales: Scalars['Int']['output'];
  weeks?: Maybe<Array<Maybe<Week>>>;
};

export type MarketStatisticChart = {
  __typename?: 'MarketStatisticChart';
  avg_discount: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  items: Scalars['Int']['output'];
  monthly_visit: Scalars['Int']['output'];
  offers: Scalars['Int']['output'];
  price_sum: Scalars['Float']['output'];
};

export type MaxBuyOrdersItemData = {
  __typename?: 'MaxBuyOrdersItemData';
  market_name: Scalars['String']['output'];
  max_buy_order_price_24h: Scalars['Int']['output'];
};

export type MaxOrder = {
  __typename?: 'MaxOrder';
  market_name: Scalars['String']['output'];
  offers: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
};

export type MaxOrderAnalytics = {
  __typename?: 'MaxOrderAnalytics';
  buyOrder: MaxBuyOrdersItemData;
  market_hash_name: Scalars['String']['output'];
};

export type MinPrice = {
  __typename?: 'MinPrice';
  market_name: Scalars['String']['output'];
  offers: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  price_locked?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addBalancePoints?: Maybe<Scalars['Boolean']['output']>;
  addUserWhiteIp: Scalars['Boolean']['output'];
  buyBalanceTokens: Scalars['String']['output'];
  buyRequests: Scalars['String']['output'];
  cacheRequest?: Maybe<Scalars['Boolean']['output']>;
  cancelPlan?: Maybe<Scalars['Boolean']['output']>;
  cancelPlanComment?: Maybe<Scalars['Boolean']['output']>;
  checkInventoryValue?: Maybe<Scalars['String']['output']>;
  createAddon: Scalars['Boolean']['output'];
  createBlogCategory: BlogCategory;
  createBlogPost: BlogPost;
  createDynamicPage: Scalars['Boolean']['output'];
  createMarket: Scalars['Boolean']['output'];
  createSubscriptionToken: Scalars['String']['output'];
  deleteAddon: Scalars['Boolean']['output'];
  deleteBlogCategory: Scalars['String']['output'];
  deleteBlogPost: Scalars['String']['output'];
  deleteDynamicPage: Scalars['Boolean']['output'];
  deleteFile: Scalars['Boolean']['output'];
  deleteMarket: Scalars['Boolean']['output'];
  deleteUserWhiteIp: Scalars['Boolean']['output'];
  dropUserEmail?: Maybe<Scalars['Boolean']['output']>;
  generateApiKey: Scalars['String']['output'];
  likeBlogPost: Scalars['String']['output'];
  likeItem?: Maybe<Scalars['Date']['output']>;
  makeVote: MakeVoteResult;
  manuallyCheckInventory?: Maybe<Scalars['Boolean']['output']>;
  selectPlan?: Maybe<Scalars['String']['output']>;
  setSocialLinks: Scalars['Boolean']['output'];
  subscribeToAddon: Scalars['Boolean']['output'];
  updateAddon: Scalars['Boolean']['output'];
  updateBlogCategory: BlogCategory;
  updateBlogPost: BlogPost;
  updateDynamicPage: Scalars['Boolean']['output'];
  updateMarket: Scalars['Boolean']['output'];
  updateUserWhiteIp: Scalars['Boolean']['output'];
  userInfo: UserInfo;
  viewBlogPost: Scalars['String']['output'];
};


export type MutationAddBalancePointsArgs = {
  points: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type MutationAddUserWhiteIpArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};


export type MutationBuyBalanceTokensArgs = {
  tokens: Scalars['Int']['input'];
};


export type MutationBuyRequestsArgs = {
  plan: BuyRequestPlan;
};


export type MutationCacheRequestArgs = {
  method: Scalars['String']['input'];
};


export type MutationCancelPlanCommentArgs = {
  cause?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  comment: Scalars['String']['input'];
};


export type MutationCheckInventoryValueArgs = {
  input: Scalars['String']['input'];
};


export type MutationCreateAddonArgs = {
  description: Scalars['String']['input'];
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  logo_url: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
};


export type MutationCreateBlogCategoryArgs = {
  input: CreateBlogCategoryInput;
};


export type MutationCreateBlogPostArgs = {
  input: CreateBlogPostInput;
};


export type MutationCreateDynamicPageArgs = {
  input: CreateDynamicPageInput;
};


export type MutationCreateMarketArgs = {
  input: CreateMarketInput;
};


export type MutationCreateSubscriptionTokenArgs = {
  api_key: Scalars['String']['input'];
};


export type MutationDeleteAddonArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeleteBlogCategoryArgs = {
  input: DeleteBlogCategoryInput;
};


export type MutationDeleteBlogPostArgs = {
  input: DeleteBlogPostInput;
};


export type MutationDeleteDynamicPageArgs = {
  slug: Scalars['String']['input'];
};


export type MutationDeleteFileArgs = {
  url: Scalars['String']['input'];
};


export type MutationDeleteMarketArgs = {
  market_name: Scalars['String']['input'];
};


export type MutationDeleteUserWhiteIpArgs = {
  value: Scalars['String']['input'];
};


export type MutationLikeBlogPostArgs = {
  input: LikeBlogPostInput;
};


export type MutationLikeItemArgs = {
  itemLink: Scalars['String']['input'];
};


export type MutationMakeVoteArgs = {
  input: MakeVoteInput;
};


export type MutationManuallyCheckInventoryArgs = {
  input: Scalars['String']['input'];
};


export type MutationSelectPlanArgs = {
  afterCurrentPlan?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<SelectPlanCurrency>;
  duration: PlanDurationType;
  plan: SelectUserPlan;
};


export type MutationSetSocialLinksArgs = {
  input?: InputMaybe<UserSocialLinkInput>;
};


export type MutationSubscribeToAddonArgs = {
  name: Scalars['String']['input'];
};


export type MutationUpdateAddonArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  logo_url?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateBlogCategoryArgs = {
  input: UpdateBlogCategoryInput;
};


export type MutationUpdateBlogPostArgs = {
  input: UpdateBlogPostInput;
};


export type MutationUpdateDynamicPageArgs = {
  input: UpdateDynamicPageInput;
};


export type MutationUpdateMarketArgs = {
  input: UpdateMarketInput;
  market_name: Scalars['String']['input'];
};


export type MutationUpdateUserWhiteIpArgs = {
  oldValue: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationUserInfoArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  steam_api_key?: InputMaybe<Scalars['String']['input']>;
  trade_link?: InputMaybe<Scalars['String']['input']>;
};


export type MutationViewBlogPostArgs = {
  input: ViewBlogPostInput;
};

export type MyAddonFullType = {
  __typename?: 'MyAddonFullType';
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  expire_at?: Maybe<Scalars['Date']['output']>;
  is_active: Scalars['Boolean']['output'];
  logo_url: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type MyAddonsListResult = {
  __typename?: 'MyAddonsListResult';
  max_pages: Scalars['Int']['output'];
  result?: Maybe<Array<MyAddonFullType>>;
};

export type OrderAnalytics = {
  __typename?: 'OrderAnalytics';
  buyOrders?: Maybe<BuyOrdersItemData>;
  listings?: Maybe<ListingsItemData>;
  market_hash_name?: Maybe<Scalars['String']['output']>;
};

export enum OrderBy {
  Alphabetically = 'ALPHABETICALLY',
  ColorMatch = 'COLOR_MATCH',
  HighestPrice = 'HIGHEST_PRICE',
  LowestPrice = 'LOWEST_PRICE',
  MostOffers = 'MOST_OFFERS',
  MostVolume = 'MOST_VOLUME',
  Popularity = 'POPULARITY',
  Rarity = 'RARITY'
}

export type PaginatedProTeams = {
  __typename?: 'PaginatedProTeams';
  max_pages: Scalars['Int']['output'];
  result: Array<Maybe<HighlightedProTeam>>;
  total_count: Scalars['Int']['output'];
};

export type PaymentHistoryItem = {
  __typename?: 'PaymentHistoryItem';
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  status?: Maybe<PaymentStatus>;
  type: PaymentType;
};

export type PaymentHistoryResult = {
  __typename?: 'PaymentHistoryResult';
  max_pages: Scalars['Int']['output'];
  result?: Maybe<Array<PaymentHistoryItem>>;
};

export enum PaymentHistorySort {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC'
}

export enum PaymentStatus {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Refunded = 'refunded'
}

export enum PaymentType {
  Payment = 'payment',
  Refund = 'refund',
  Setup = 'setup',
  Subscription = 'subscription'
}

export enum Period {
  All = 'ALL',
  OneMonth = 'ONE_MONTH',
  SevenDays = 'SEVEN_DAYS',
  ThreeMonths = 'THREE_MONTHS'
}

export type PhaseItem = {
  __typename?: 'PhaseItem';
  link_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  phase: Scalars['String']['output'];
};

export enum PlanDurationType {
  Annualy = 'annualy',
  Monthly = 'monthly'
}

export type PriceRange = {
  __typename?: 'PriceRange';
  highest_link?: Maybe<Scalars['String']['output']>;
  highest_price?: Maybe<Scalars['Int']['output']>;
  lowest_link?: Maybe<Scalars['String']['output']>;
  lowest_price?: Maybe<Scalars['Int']['output']>;
  market_hash_name_highest?: Maybe<Scalars['String']['output']>;
  market_hash_name_lowest?: Maybe<Scalars['String']['output']>;
};

export type PriceStatistics = {
  __typename?: 'PriceStatistics';
  all_time_high?: Maybe<Scalars['Int']['output']>;
  all_time_low?: Maybe<Scalars['Int']['output']>;
  ninety_day_high?: Maybe<Scalars['Int']['output']>;
  ninety_day_low?: Maybe<Scalars['Int']['output']>;
  seven_day_average?: Maybe<Scalars['Int']['output']>;
  seven_day_chart?: Maybe<Array<DailyPriceChart>>;
  seven_day_high?: Maybe<Scalars['Int']['output']>;
  seven_day_low?: Maybe<Scalars['Int']['output']>;
  thirty_day_average?: Maybe<Scalars['Int']['output']>;
  thirty_day_high?: Maybe<Scalars['Int']['output']>;
  thirty_day_low?: Maybe<Scalars['Int']['output']>;
};

export type ProPlayer = {
  __typename?: 'ProPlayer';
  general: ProPlayerGeneral;
  id: Scalars['Float']['output'];
  social: ProPlayerSocial;
};

export type ProPlayerCrosshair = {
  __typename?: 'ProPlayerCrosshair';
  crosshair_alpha?: Maybe<Scalars['String']['output']>;
  crosshair_alpha_value?: Maybe<Scalars['Float']['output']>;
  crosshair_blue?: Maybe<Scalars['Float']['output']>;
  crosshair_color?: Maybe<Scalars['String']['output']>;
  crosshair_deployed_weapon_gap?: Maybe<Scalars['String']['output']>;
  crosshair_dot?: Maybe<Scalars['String']['output']>;
  crosshair_fixed_gap?: Maybe<Scalars['String']['output']>;
  crosshair_follow_recoil?: Maybe<Scalars['String']['output']>;
  crosshair_gap?: Maybe<Scalars['String']['output']>;
  crosshair_green?: Maybe<Scalars['Float']['output']>;
  crosshair_image?: Maybe<Scalars['String']['output']>;
  crosshair_in_game_config?: Maybe<Scalars['String']['output']>;
  crosshair_inner_split_alpha?: Maybe<Scalars['String']['output']>;
  crosshair_length?: Maybe<Scalars['String']['output']>;
  crosshair_outer_split_alpha?: Maybe<Scalars['String']['output']>;
  crosshair_outline?: Maybe<Scalars['String']['output']>;
  crosshair_outline_thickness?: Maybe<Scalars['String']['output']>;
  crosshair_red?: Maybe<Scalars['Float']['output']>;
  crosshair_sniper_width?: Maybe<Scalars['String']['output']>;
  crosshair_split_distance?: Maybe<Scalars['String']['output']>;
  crosshair_split_size_ratio?: Maybe<Scalars['String']['output']>;
  crosshair_style?: Maybe<Scalars['String']['output']>;
  crosshair_t_style?: Maybe<Scalars['String']['output']>;
  crosshair_thickness?: Maybe<Scalars['String']['output']>;
};

export type ProPlayerGeneral = {
  __typename?: 'ProPlayerGeneral';
  avatar?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  country_logo?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  nick_name: Scalars['String']['output'];
  profile_url?: Maybe<Scalars['String']['output']>;
  real_name?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  team_logo?: Maybe<Scalars['String']['output']>;
};

export type ProPlayerMouse = {
  __typename?: 'ProPlayerMouse';
  mouse_dpi?: Maybe<Scalars['Float']['output']>;
  mouse_edpi?: Maybe<Scalars['Float']['output']>;
  mouse_hz?: Maybe<Scalars['Float']['output']>;
  mouse_model?: Maybe<Scalars['String']['output']>;
  mouse_sensitivity?: Maybe<Scalars['String']['output']>;
  mouse_windows_sensitivity?: Maybe<Scalars['Float']['output']>;
  mouse_zoom_sensitivity?: Maybe<Scalars['String']['output']>;
};

export type ProPlayerPaginatedItems = {
  __typename?: 'ProPlayerPaginatedItems';
  max_pages: Scalars['Int']['output'];
  result: Array<AdvancedSearchBody>;
  total_count: Scalars['Int']['output'];
};

export type ProPlayerSocial = {
  __typename?: 'ProPlayerSocial';
  instagram?: Maybe<Scalars['String']['output']>;
  steam?: Maybe<Scalars['String']['output']>;
  twitch?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type ProPlayerViewmodel = {
  __typename?: 'ProPlayerViewmodel';
  viewmodel_fov?: Maybe<Scalars['Float']['output']>;
  viewmodel_in_game_config?: Maybe<Scalars['String']['output']>;
  viewmodel_offset_x?: Maybe<Scalars['String']['output']>;
  viewmodel_offset_y?: Maybe<Scalars['String']['output']>;
  viewmodel_offset_z?: Maybe<Scalars['String']['output']>;
  viewmodel_preset_pos?: Maybe<Scalars['Float']['output']>;
};

export type ProPlayerWithItems = {
  __typename?: 'ProPlayerWithItems';
  config_file?: Maybe<Scalars['String']['output']>;
  crosshair: ProPlayerCrosshair;
  gears: Array<Maybe<Gear>>;
  general: ProPlayerGeneral;
  id: Scalars['Float']['output'];
  items: ProPlayerPaginatedItems;
  mouse: ProPlayerMouse;
  social: ProPlayerSocial;
  viewmodel: ProPlayerViewmodel;
};

export type ProPlayersPaginationInput = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type ProTeam = {
  __typename?: 'ProTeam';
  description?: Maybe<Scalars['String']['output']>;
  gears: Array<Maybe<Gear>>;
  id: Scalars['Int']['output'];
  link?: Maybe<Scalars['String']['output']>;
  logo: Scalars['String']['output'];
  players: Array<Maybe<ProPlayer>>;
  social?: Maybe<ProPlayerSocial>;
  title: Scalars['String']['output'];
};

export type ProfitableTradeUpContract = {
  __typename?: 'ProfitableTradeUpContract';
  cost: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  profit: Scalars['Float']['output'];
  resultItem: Scalars['String']['output'];
  resultItemLink: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  advancedSearch: AdvancedSearchResult;
  advancedSearchV2: AdvancedSearchResult;
  aggregatedMaxOrders: Array<Maybe<AggregatedMaxOrderItem>>;
  aggregatedMinPrices: Array<Maybe<AggregatedMinPriceItem>>;
  aggregatedMinPricesTEST: Array<Maybe<AggregatedMinPriceItem>>;
  allAddons: AddonsListResult;
  availableMarkets: Array<Maybe<MarketInfo>>;
  collectionInfo?: Maybe<CollectionInfo>;
  collections: Array<CollectionInfo>;
  compareMarketsInfo: ComparisonMarketInfo;
  comparePageFilters: ComparisonFiltersData;
  comparePageInfo: CompareOutput;
  dynamicPage?: Maybe<DynamicPageInfo>;
  getBlogCategories: Array<Maybe<BlogCategory>>;
  getBlogCategory: BlogCategory;
  getBlogPost: BlogPost;
  getBlogPosts?: Maybe<GetBlogPostsOutput>;
  getBuff163Analytics: Array<Maybe<OrderAnalytics>>;
  getCategories: Array<Maybe<Category>>;
  /** @deprecated Use ItemPageInfo.rating  */
  getItemRating: GetItemRatingResult;
  getItemsData: Array<Maybe<ItemData>>;
  getMainBlogPost: BlogPost;
  getMarketOrderAnalytics: Array<Maybe<OrderAnalytics>>;
  getMarketsListings: Array<Maybe<MarketListing>>;
  getPriceRecommendation: RecommendationPrice;
  getPriceRecommendations: Array<Maybe<RecommendationPrice>>;
  getProPlayer: ProPlayerWithItems;
  getProPlayers: Array<Maybe<ProPlayer>>;
  getProTeam: ProTeam;
  getProTeams: PaginatedProTeams;
  getSteamAnalytics: Array<Maybe<SteamAnalytics>>;
  getSteamAnalyticsForItem: SteamAnalytics;
  getSteamRGAssets: Array<Maybe<RgAssets>>;
  getUserInventories?: Maybe<GetUserInventoriesOutput>;
  getUserInventoryRaw?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated Use ItemPageInfo.userVote  */
  getUserVote?: Maybe<Scalars['Int']['output']>;
  inventoriesCommonInfo: InventoriesCommonInfo;
  inventoryMostPopularItems: Array<Maybe<InventoryRarePopularItem>>;
  inventoryRarestItems: Array<Maybe<InventoryRarePopularItem>>;
  inventoryTradeUpItems?: Maybe<Scalars['String']['output']>;
  inventoryValueChart: Array<Maybe<InventoryValueChart>>;
  itemColorGroup: Array<ItemColorGroupResult>;
  itemPageInfo: ItemPageInfo;
  itemStatsByPeriod: ItemStatsByPeriod;
  itemsContainerPage?: Maybe<ItemsContainerPageResult>;
  itemsCountByColor?: Maybe<Array<Maybe<ItemCountByColor>>>;
  itemsCountByColors?: Maybe<Array<Maybe<ItemCountByColor>>>;
  itemsPriceDiffs: Array<Maybe<ItemsPriceDiffs>>;
  itemsSteamVolumeStatistic: ItemsSteamVolumeStatisticResponse;
  itemsSteamVolumeStatisticChart?: Maybe<Array<Maybe<ItemsSteamVolumeStatisticSkinChart>>>;
  itemsWeaponPage?: Maybe<ItemsByFieldResult>;
  itemsWeaponPageFilters?: Maybe<ItemWeaponPageFiltersResult>;
  latestManualCheckedInventory: LatestManualCheckedInventory;
  latestProfitableContracts: Array<Maybe<ProfitableTradeUpContract>>;
  market?: Maybe<MarketFullInfo>;
  marketForAdmin: MarketAdminResponse;
  marketStatForPeriod: MarketStatForPeriod;
  marketsMainStats: Array<Maybe<MarketStat>>;
  myAddons: MyAddonsListResult;
  paymentHistory: PaymentHistoryResult;
  paymentInvoice: Scalars['String']['output'];
  profitableTradeUpContract: TradeUpContract;
  randomInventory: Array<Maybe<RandomInventory>>;
  requestStatistic: RequestStatisticResponse;
  requestStats: RequestStatsResult;
  searchParams: SearchParams;
  serviceUseStatisticChart?: Maybe<Array<ServiceCallStatisticChartData>>;
  simpleSearch: Array<Maybe<SimpleSearch>>;
  tournamentInfo?: Maybe<TournamentInfo>;
  tournamentItemCategories: Array<Scalars['String']['output']>;
  tournamentItems: TournamentItemsResult;
  tournaments?: Maybe<Array<Maybe<Tournament>>>;
  tradeUpCalculate?: Maybe<Array<Maybe<TradeUpCalculateQueryResult>>>;
  userFinder?: Maybe<UserFinder>;
  userFullInfo: UserFullInfo;
  userFullInventory: UserFullInventoryOutput;
  userInfo: UserInfo;
  userInventoryInlineSearch: Array<Maybe<UserInventoryInlineSearchOutput>>;
  userInventoryItem?: Maybe<UserInventoryItemOutput>;
  userInventoryProfile: UserInventoryProfile;
};


export type QueryAdvancedSearchArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  searchParams: AdvancedSearchInput;
};


export type QueryAdvancedSearchV2Args = {
  lang?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  searchParams: AdvancedSearchInputV2;
};


export type QueryAggregatedMaxOrdersArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  markets: Array<Scalars['String']['input']>;
};


export type QueryAggregatedMinPricesArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  markets: Array<Scalars['String']['input']>;
};


export type QueryAggregatedMinPricesTestArgs = {
  game_id: Scalars['Int']['input'];
  markets: Array<Scalars['String']['input']>;
};


export type QueryAllAddonsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page: Scalars['Int']['input'];
};


export type QueryAvailableMarketsArgs = {
  order?: InputMaybe<MarketOrderByInput>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCollectionInfoArgs = {
  link_name: Scalars['String']['input'];
};


export type QueryCollectionsArgs = {
  group?: InputMaybe<CollectionGroup>;
  sort: CollectionYearSort;
};


export type QueryCompareMarketsInfoArgs = {
  input: ComparisonMarketsInput;
};


export type QueryComparePageInfoArgs = {
  input: ComparisonInput;
};


export type QueryGetBlogCategoriesArgs = {
  input: GetBlogCategoriesInput;
};


export type QueryGetBlogCategoryArgs = {
  input: GetBlogCategoryInput;
};


export type QueryGetBlogPostArgs = {
  input: GetBlogPostInput;
};


export type QueryGetBlogPostsArgs = {
  pageParams: GetBlogPostsPageParamsInput;
  searchParams: GetBlogPostsSearchParamsInput;
};


export type QueryGetItemRatingArgs = {
  itemID: Scalars['Int']['input'];
};


export type QueryGetItemsDataArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetMainBlogPostArgs = {
  input?: InputMaybe<GetMainBlogPostInput>;
};


export type QueryGetMarketOrderAnalyticsArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  market_name: Scalars['String']['input'];
};


export type QueryGetMarketsListingsArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPriceRecommendationArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  market_hash_name: Scalars['String']['input'];
  type?: InputMaybe<RecommendationType>;
};


export type QueryGetPriceRecommendationsArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<RecommendationType>;
};


export type QueryGetProPlayerArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  nick_name: Scalars['String']['input'];
  pagination: ProPlayersPaginationInput;
};


export type QueryGetProPlayersArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetProTeamArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
};


export type QueryGetProTeamsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  pagination: ProPlayersPaginationInput;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetSteamAnalyticsArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetSteamAnalyticsForItemArgs = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  market_hash_name: Scalars['String']['input'];
};


export type QueryGetSteamRgAssetsArgs = {
  game_id: Scalars['Int']['input'];
};


export type QueryGetUserInventoriesArgs = {
  input: GetUserInventoriesInput;
};


export type QueryGetUserInventoryRawArgs = {
  input: Scalars['String']['input'];
};


export type QueryGetUserVoteArgs = {
  marketHashName: Scalars['String']['input'];
};


export type QueryInventoriesCommonInfoArgs = {
  market_name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInventoryMostPopularItemsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInventoryRarestItemsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInventoryTradeUpItemsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
};


export type QueryInventoryValueChartArgs = {
  market_name: Scalars['String']['input'];
  period: InventoryValueChartPeriod;
  steamid64: Scalars['String']['input'];
};


export type QueryItemPageInfoArgs = {
  itemLink: Scalars['String']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryItemStatsByPeriodArgs = {
  itemName: Scalars['String']['input'];
  marketName: Scalars['String']['input'];
  period: Period;
};


export type QueryItemsContainerPageArgs = {
  containerLinkName: Scalars['String']['input'];
  exteriors?: InputMaybe<Array<Scalars['String']['input']>>;
  isRareSpecial: Scalars['Boolean']['input'];
  isStatTrak?: InputMaybe<Scalars['Boolean']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryItemsCountByColorArgs = {
  colors: Array<Scalars['String']['input']>;
};


export type QueryItemsPriceDiffsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryItemsSteamVolumeStatisticChartArgs = {
  period: Period;
  weapon: Scalars['String']['input'];
};


export type QueryItemsWeaponPageArgs = {
  input: WeaponPageInput;
};


export type QueryItemsWeaponPageFiltersArgs = {
  category: Scalars['String']['input'];
};


export type QueryLatestManualCheckedInventoryArgs = {
  market_name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMarketArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};


export type QueryMarketForAdminArgs = {
  slug: Scalars['String']['input'];
};


export type QueryMarketStatForPeriodArgs = {
  marketName: Scalars['String']['input'];
  period: Period;
};


export type QueryMyAddonsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page: Scalars['Int']['input'];
};


export type QueryPaymentHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page: Scalars['Int']['input'];
  sort?: InputMaybe<PaymentHistorySort>;
};


export type QueryProfitableTradeUpContractArgs = {
  id: Scalars['Int']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRandomInventoryArgs = {
  exclude_steamid64?: InputMaybe<Scalars['String']['input']>;
  market_name: Scalars['String']['input'];
};


export type QueryServiceUseStatisticChartArgs = {
  period: ServiceCallStatisticChartPeriod;
};


export type QuerySimpleSearchArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  searchText: Scalars['String']['input'];
};


export type QueryTournamentInfoArgs = {
  link_name: Scalars['String']['input'];
};


export type QueryTournamentItemCategoriesArgs = {
  link_name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTournamentItemsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page: Scalars['Int']['input'];
  searchParams: TournamentItemsInput;
};


export type QueryTournamentsArgs = {
  sort: TournamentYearSort;
};


export type QueryTradeUpCalculateArgs = {
  input: TradeUpCalculatorInput;
};


export type QueryUserFinderArgs = {
  input: Scalars['String']['input'];
};


export type QueryUserFullInventoryArgs = {
  input: UserFullInventoryInput;
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserInventoryInlineSearchArgs = {
  input: Scalars['String']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserInventoryItemArgs = {
  input: UserInventoryItemInput;
  lang?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserInventoryProfileArgs = {
  input: UserInventoryProfileInput;
};

export type RgAssets = {
  __typename?: 'RGAssets';
  data: Scalars['JSON']['output'];
  game_id: Scalars['Int']['output'];
  market_hash_name: Scalars['String']['output'];
};

export type RandomInventory = {
  __typename?: 'RandomInventory';
  avatar_url: Scalars['String']['output'];
  persona_name: Scalars['String']['output'];
  steamid64: Scalars['String']['output'];
  total_value: Scalars['String']['output'];
};

export type RaritiesCount = {
  __typename?: 'RaritiesCount';
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type RecommendationPrice = {
  __typename?: 'RecommendationPrice';
  market_hash_name: Scalars['String']['output'];
  meta_info?: Maybe<Scalars['JSON']['output']>;
  price_change_percentage?: Maybe<Scalars['Float']['output']>;
  price_difference?: Maybe<Scalars['Float']['output']>;
  suggested_price: Scalars['Float']['output'];
  total_sales: Scalars['Int']['output'];
  unstable_reasons: Array<Maybe<Scalars['String']['output']>>;
};

export enum RecommendationType {
  General = 'general',
  P2p = 'p2p'
}

export type RequestStatisticPopularService = {
  __typename?: 'RequestStatisticPopularService';
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type RequestStatisticResponse = {
  __typename?: 'RequestStatisticResponse';
  avg_request_per_day: Scalars['Float']['output'];
  popular_service?: Maybe<RequestStatisticPopularService>;
  requests_per_month: Scalars['Int']['output'];
};

export type RequestStatsResult = {
  __typename?: 'RequestStatsResult';
  all: Scalars['Int']['output'];
  used: Scalars['Int']['output'];
};

export enum SalesPeriod {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type SearchParamColor = {
  __typename?: 'SearchParamColor';
  hex: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SearchParams = {
  __typename?: 'SearchParams';
  categories: Array<Maybe<Scalars['String']['output']>>;
  collections: Array<Maybe<Scalars['String']['output']>>;
  color_group: Array<ColorGroup>;
  colors: Array<SearchParamColor>;
  exteriors: Array<Maybe<Scalars['String']['output']>>;
  extra: Array<Maybe<Extra>>;
  group_by?: Maybe<Array<Maybe<GroupBy>>>;
  order_by: Array<Maybe<OrderBy>>;
  phases: Array<Maybe<Scalars['String']['output']>>;
  rarities: Array<Maybe<Scalars['String']['output']>>;
  sticker_effects: Array<Maybe<Scalars['String']['output']>>;
  teams: Array<Maybe<Scalars['String']['output']>>;
  tournament_events: Array<Maybe<Scalars['String']['output']>>;
  weapons: Array<Maybe<Scalars['String']['output']>>;
};

export enum SelectPlanCurrency {
  Token = 'token',
  Usd = 'usd'
}

export enum SelectUserPlan {
  Pro = 'pro',
  Standard = 'standard'
}

export type ServiceCallStatisticChartData = {
  __typename?: 'ServiceCallStatisticChartData';
  end_date: Scalars['Date']['output'];
  service: Scalars['String']['output'];
  start_date: Scalars['Date']['output'];
  total_count: Scalars['Int']['output'];
};

export enum ServiceCallStatisticChartPeriod {
  All = 'ALL',
  Month = 'MONTH',
  ThreeMonth = 'THREE_MONTH',
  Week = 'WEEK'
}

export type SimpleSearch = {
  __typename?: 'SimpleSearch';
  breadcrumbs: Array<Maybe<SimpleSearchBreadCrumb>>;
  document: SimpleSearchDocument;
};

export type SimpleSearchBreadCrumb = {
  __typename?: 'SimpleSearchBreadCrumb';
  link: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SimpleSearchDocument = {
  __typename?: 'SimpleSearchDocument';
  background_color?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum SkinCategory {
  Normal = 'NORMAL',
  Rare = 'RARE',
  RareStattrak = 'RARE_STATTRAK',
  Souvenir = 'SOUVENIR',
  Stattrak = 'STATTRAK'
}

export type SteamAnalytics = {
  __typename?: 'SteamAnalytics';
  buyOrders?: Maybe<SteamBuyOrdersItemData>;
  listings?: Maybe<SteamListingsItemData>;
  market_hash_name?: Maybe<Scalars['String']['output']>;
  sales?: Maybe<SteamSalesItemData>;
};

export type SteamBuyOrdersItemData = {
  __typename?: 'SteamBuyOrdersItemData';
  average_buy_price_90d?: Maybe<Scalars['Float']['output']>;
  buy_orders_last_7d?: Maybe<Scalars['Int']['output']>;
  buy_orders_last_24h?: Maybe<Scalars['Int']['output']>;
  latest_buy_order_price?: Maybe<Scalars['Float']['output']>;
  max_buy_order_price?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_7d?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_24h?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_30d?: Maybe<Scalars['Int']['output']>;
  max_buy_order_price_90d?: Maybe<Scalars['Int']['output']>;
  median_buy_price_90d?: Maybe<Scalars['Int']['output']>;
  min_buy_order_price?: Maybe<Scalars['Int']['output']>;
};

export type SteamListingsItemData = {
  __typename?: 'SteamListingsItemData';
  average_sell_price_90d?: Maybe<Scalars['Float']['output']>;
  latest_sell_order_price?: Maybe<Scalars['Float']['output']>;
  max_sell_order_price?: Maybe<Scalars['Int']['output']>;
  median_sell_price_90d?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_7d?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_24h?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_30d?: Maybe<Scalars['Int']['output']>;
  min_sell_order_price_90d?: Maybe<Scalars['Int']['output']>;
  sell_orders_last_7d?: Maybe<Scalars['Int']['output']>;
  sell_orders_last_24h?: Maybe<Scalars['Int']['output']>;
};

export type SteamSalesItemData = {
  __typename?: 'SteamSalesItemData';
  average_price_90d?: Maybe<Scalars['Float']['output']>;
  avg_daily_volume?: Maybe<Scalars['Float']['output']>;
  last_7d?: Maybe<Scalars['Int']['output']>;
  last_24h?: Maybe<Scalars['Int']['output']>;
  last_30d?: Maybe<Scalars['Int']['output']>;
  last_90d?: Maybe<Scalars['Int']['output']>;
  latest_median_price_sold?: Maybe<Scalars['Float']['output']>;
  median_price_90d?: Maybe<Scalars['Float']['output']>;
};

export type StickerLocals = {
  __typename?: 'StickerLocals';
  market_hash_name: Scalars['String']['output'];
};

export enum Teams {
  Both = 'BOTH',
  Ct = 'CT',
  T = 'T'
}

export type Tournament = {
  __typename?: 'Tournament';
  city: Scalars['String']['output'];
  link_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type TournamentInfo = {
  __typename?: 'TournamentInfo';
  city: Scalars['String']['output'];
  name: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type TournamentItemsInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  event_link: Scalars['String']['input'];
  orderBy: OrderBy;
};

export type TournamentItemsResult = {
  __typename?: 'TournamentItemsResult';
  items_length: Scalars['Int']['output'];
  max_pages: Scalars['Int']['output'];
  result: Array<Maybe<AdvancedSearchBody>>;
};

export enum TournamentYearSort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type TradeUpCalculateQueryResult = {
  __typename?: 'TradeUpCalculateQueryResult';
  collection: Scalars['String']['output'];
  dropPercentage: Scalars['Float']['output'];
  float: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  marketHashName: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  rarity: Scalars['String']['output'];
};

export type TradeUpCalculatorInput = {
  allowStattrek: Scalars['Boolean']['input'];
  items: Array<TradeUpCalculatorItem>;
};

export type TradeUpCalculatorItem = {
  float: Scalars['Float']['input'];
  market_hash_name: Scalars['String']['input'];
};

export type TradeUpContract = {
  __typename?: 'TradeUpContract';
  cost: Scalars['Float']['output'];
  items: Array<TradeUpItem>;
  market_hash_name: Scalars['String']['output'];
  profit: Scalars['Float']['output'];
};

export type TradeUpContractLocal = {
  __typename?: 'TradeUpContractLocal';
  localed_name: Scalars['String']['output'];
  skin: Scalars['String']['output'];
};

export type TradeUpItem = {
  __typename?: 'TradeUpItem';
  collection: Scalars['String']['output'];
  exterior: Scalars['String']['output'];
  float: Scalars['Float']['output'];
  item_max_float: Scalars['Float']['output'];
  item_min_float: Scalars['Float']['output'];
  link: Scalars['String']['output'];
  locals: TradeUpContractLocal;
  market_hash_name: Scalars['String']['output'];
  markets_info: Array<ContractItemMarketInfo>;
  rarity_color: Scalars['String']['output'];
  volume?: Maybe<Array<Maybe<ContractItemMarketVolume>>>;
};

export type UpdateBlogCategoryInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Array<BlogCategoryDataInput>>;
  link: Scalars['String']['input'];
};

export type UpdateBlogPostInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  img?: InputMaybe<Scalars['String']['input']>;
  is_main?: InputMaybe<Scalars['Boolean']['input']>;
  lang: Scalars['String']['input'];
  link: Scalars['String']['input'];
  published_at?: InputMaybe<Scalars['Date']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDynamicPageInput = {
  advanced_search_parameters?: InputMaybe<AdvancedSearchInput>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  locals?: InputMaybe<Array<DynamicPageLocal>>;
  slug: Scalars['String']['input'];
};

export type UpdateMarketInput = {
  apps: Array<InputMaybe<MarketInfoAppInput>>;
  foundation_date?: InputMaybe<Scalars['Date']['input']>;
  is_active: Scalars['Boolean']['input'];
  locals: Array<InputMaybe<MarketInfoLocalInput>>;
  logo_name: Scalars['String']['input'];
  market_full_name: Scalars['String']['input'];
  market_name: Scalars['String']['input'];
  market_type: Scalars['String']['input'];
  payment_methods: Array<InputMaybe<MarketInfoPaymentMethodInput>>;
  payout_methods: Array<InputMaybe<MarketInfoPayoutMethodInput>>;
  rating: Scalars['Float']['input'];
  registered_company?: InputMaybe<Scalars['String']['input']>;
  seller_fee: Scalars['Float']['input'];
  social_media: MarketInfoSocialMediaInput;
  trust_pilot_link: Scalars['String']['input'];
  votes: Scalars['Float']['input'];
  website_url?: InputMaybe<Scalars['String']['input']>;
};

export type UserFinder = {
  __typename?: 'UserFinder';
  avatar_url: Scalars['String']['output'];
  bans: UserFinderBans;
  custom_url?: Maybe<Scalars['String']['output']>;
  friends_count: Scalars['Int']['output'];
  ids: UserFinderSteamIds;
  level: Scalars['Int']['output'];
  location?: Maybe<UserFinderLocation>;
  persona_name: Scalars['String']['output'];
  profile: Scalars['String']['output'];
  real_name: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type UserFinderBans = {
  __typename?: 'UserFinderBans';
  community: Scalars['String']['output'];
  game: Scalars['String']['output'];
  trade: Scalars['String']['output'];
  vac: Scalars['String']['output'];
};

export type UserFinderLocation = {
  __typename?: 'UserFinderLocation';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  coutry_code?: Maybe<Scalars['String']['output']>;
};

export type UserFinderSteamIds = {
  __typename?: 'UserFinderSteamIds';
  steamid2: Scalars['String']['output'];
  steamid3: Scalars['String']['output'];
  steamid32: Scalars['String']['output'];
  steamid64: Scalars['String']['output'];
  steamid64hex: Scalars['String']['output'];
};

export type UserFullInfo = {
  __typename?: 'UserFullInfo';
  api_key?: Maybe<Scalars['String']['output']>;
  api_request_left?: Maybe<Scalars['Int']['output']>;
  avatar_url: Scalars['String']['output'];
  balance: Scalars['Int']['output'];
  coutry_code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  is_admin: Scalars['Boolean']['output'];
  is_email_verified?: Maybe<Scalars['Boolean']['output']>;
  persona_name: Scalars['String']['output'];
  plan?: Maybe<UserPlan>;
  plan_expire_at?: Maybe<Scalars['Date']['output']>;
  real_name?: Maybe<Scalars['String']['output']>;
  social_links: UserSocialLink;
  steam_api_key?: Maybe<Scalars['String']['output']>;
  steamid64: Scalars['String']['output'];
  trade_link?: Maybe<Scalars['String']['output']>;
  white_ips?: Maybe<Array<Maybe<WhiteIp>>>;
};

export type UserFullInventoryInput = {
  market: Scalars['String']['input'];
  steam64: Scalars['String']['input'];
};

export type UserFullInventoryOutput = {
  __typename?: 'UserFullInventoryOutput';
  assets?: Maybe<Scalars['JSON']['output']>;
  price_data?: Maybe<Scalars['JSON']['output']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  avatar_url: Scalars['String']['output'];
  balance: Scalars['Int']['output'];
  coutry_code?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  is_admin: Scalars['Boolean']['output'];
  persona_name: Scalars['String']['output'];
  real_name?: Maybe<Scalars['String']['output']>;
  steam_api_key?: Maybe<Scalars['String']['output']>;
  steamid64: Scalars['String']['output'];
  trade_link?: Maybe<Scalars['String']['output']>;
};

export type UserInventoryInlineSearchOutput = {
  __typename?: 'UserInventoryInlineSearchOutput';
  highlighted_name: Scalars['String']['output'];
  market_hash_name: Scalars['String']['output'];
  rarity?: Maybe<Scalars['String']['output']>;
  rarity_color?: Maybe<Scalars['String']['output']>;
};

export type UserInventoryItemInput = {
  asset_id: Scalars['String']['input'];
  market: Scalars['String']['input'];
};

export type UserInventoryItemLocals = {
  __typename?: 'UserInventoryItemLocals';
  description?: Maybe<Scalars['String']['output']>;
  market_hash_name: Scalars['String']['output'];
  rarity?: Maybe<Scalars['String']['output']>;
  skin?: Maybe<Scalars['String']['output']>;
};

export type UserInventoryItemOutput = {
  __typename?: 'UserInventoryItemOutput';
  exterior?: Maybe<Scalars['String']['output']>;
  item_page_link: Scalars['String']['output'];
  locals: UserInventoryItemLocals;
  market_hash_name: Scalars['String']['output'];
  min_price?: Maybe<Scalars['Int']['output']>;
  price_change_percentage?: Maybe<Scalars['Float']['output']>;
  recommended_price?: Maybe<Scalars['Int']['output']>;
  stickers: Array<UserInventoryItemSticker>;
};

export type UserInventoryItemSticker = {
  __typename?: 'UserInventoryItemSticker';
  item_page_link: Scalars['String']['output'];
  locals: StickerLocals;
  market_hash_name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
};

export type UserInventoryProfile = {
  __typename?: 'UserInventoryProfile';
  avatar_url: Scalars['String']['output'];
  inventory_updated_at?: Maybe<Scalars['Date']['output']>;
  persona_name: Scalars['String']['output'];
  pro_player_nickname?: Maybe<Scalars['String']['output']>;
  profile_url: Scalars['String']['output'];
  social_links?: Maybe<UserSocialLink>;
  steam64: Scalars['String']['output'];
  steam_state?: Maybe<Scalars['String']['output']>;
  total_value_change_percentage: Scalars['Float']['output'];
};

export type UserInventoryProfileInput = {
  market: Scalars['String']['input'];
  steam64: Scalars['String']['input'];
};

export enum UserPlan {
  Business = 'business',
  Free = 'free',
  Pro = 'pro',
  Standard = 'standard',
  Team = 'team'
}

export type UserSocialLink = {
  __typename?: 'UserSocialLink';
  facebook?: Maybe<Scalars['String']['output']>;
  github?: Maybe<Scalars['String']['output']>;
  google?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  reddit?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  vk?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type UserSocialLinkInput = {
  facebook?: InputMaybe<Scalars['String']['input']>;
  github?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  reddit?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  vk?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

export type ViewBlogPostInput = {
  link: Scalars['String']['input'];
};

export type Volume = {
  __typename?: 'Volume';
  extra_full_name?: Maybe<Scalars['String']['output']>;
  extra_last_update?: Maybe<Scalars['String']['output']>;
  extra_link?: Maybe<Scalars['String']['output']>;
  extra_market?: Maybe<Scalars['String']['output']>;
  extra_offers?: Maybe<Scalars['Int']['output']>;
  extra_price?: Maybe<Scalars['Int']['output']>;
  full_name: Scalars['String']['output'];
  last_update?: Maybe<Scalars['String']['output']>;
  link: Scalars['String']['output'];
  market?: Maybe<Scalars['String']['output']>;
  min_price?: Maybe<Scalars['Int']['output']>;
  offers?: Maybe<Scalars['Int']['output']>;
};

export type WeaponPageInput = {
  category: Scalars['String']['input'];
  extra?: InputMaybe<ExtraWeaponPage>;
  lang?: InputMaybe<Scalars['String']['input']>;
  page: Scalars['Int']['input'];
  rarity?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Teams>;
};

export type Week = {
  __typename?: 'Week';
  total_count: Scalars['String']['output'];
  total_value: Scalars['String']['output'];
  week_end_date: Scalars['String']['output'];
  week_start_date: Scalars['String']['output'];
};

export type WeeklyChart = {
  __typename?: 'WeeklyChart';
  avg_price: Scalars['Int']['output'];
  max_price: Scalars['Int']['output'];
  min_price: Scalars['Int']['output'];
  total_count: Scalars['String']['output'];
  total_value: Scalars['String']['output'];
  week_end_date: Scalars['String']['output'];
  week_start_date: Scalars['String']['output'];
};

export type WhiteIp = {
  __typename?: 'WhiteIp';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};
