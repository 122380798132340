import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import intercom_client_vr7NPky0pK from "/app/plugins/intercom.client.ts";
import yandex_metrika_client_MiNHrQhx1f from "/app/plugins/yandex-metrika.client.ts";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/app/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_UYiXMU8ZyN from "/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import nuxt_plugin_6wEQMY3tee from "/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import click_outside_D2NiLGp7dJ from "/app/plugins/click-outside.ts";
import intersection_observer_tLWXWR0GRY from "/app/plugins/intersection-observer.ts";
import maska_client_D8wFsCvpJf from "/app/plugins/maska.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import socket_xW42qVsBqW from "/app/plugins/socket.ts";
import tooltip_Di9mlKVbnI from "/app/plugins/tooltip.ts";
import vue3_notification_client_NVZ5xPeu14 from "/app/plugins/vue3-notification.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  intercom_client_vr7NPky0pK,
  yandex_metrika_client_MiNHrQhx1f,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_client_UYiXMU8ZyN,
  nuxt_plugin_6wEQMY3tee,
  click_outside_D2NiLGp7dJ,
  intersection_observer_tLWXWR0GRY,
  maska_client_D8wFsCvpJf,
  sentry_client_shVUlIjFLk,
  socket_xW42qVsBqW,
  tooltip_Di9mlKVbnI,
  vue3_notification_client_NVZ5xPeu14
]