
// @ts-nocheck


export const localeCodes =  [
  "ch",
  "da",
  "de",
  "en",
  "es",
  "fr",
  "nl",
  "pl",
  "pt",
  "ro",
  "ru",
  "sv",
  "tr",
  "uk"
]

export const localeLoaders = {
  "ch": [{ key: "../locales/ch.json", load: () => import("../locales/ch.json" /* webpackChunkName: "locale__app_locales_ch_json" */), cache: true }],
  "da": [{ key: "../locales/da.json", load: () => import("../locales/da.json" /* webpackChunkName: "locale__app_locales_da_json" */), cache: true }],
  "de": [{ key: "../locales/de.json", load: () => import("../locales/de.json" /* webpackChunkName: "locale__app_locales_de_json" */), cache: true }],
  "en": [{ key: "../locales/en.json", load: () => import("../locales/en.json" /* webpackChunkName: "locale__app_locales_en_json" */), cache: true }],
  "es": [{ key: "../locales/es.json", load: () => import("../locales/es.json" /* webpackChunkName: "locale__app_locales_es_json" */), cache: true }],
  "fr": [{ key: "../locales/fr.json", load: () => import("../locales/fr.json" /* webpackChunkName: "locale__app_locales_fr_json" */), cache: true }],
  "nl": [{ key: "../locales/nl.json", load: () => import("../locales/nl.json" /* webpackChunkName: "locale__app_locales_nl_json" */), cache: true }],
  "pl": [{ key: "../locales/pl.json", load: () => import("../locales/pl.json" /* webpackChunkName: "locale__app_locales_pl_json" */), cache: true }],
  "pt": [{ key: "../locales/pt.json", load: () => import("../locales/pt.json" /* webpackChunkName: "locale__app_locales_pt_json" */), cache: true }],
  "ro": [{ key: "../locales/ro.json", load: () => import("../locales/ro.json" /* webpackChunkName: "locale__app_locales_ro_json" */), cache: true }],
  "ru": [{ key: "../locales/ru.json", load: () => import("../locales/ru.json" /* webpackChunkName: "locale__app_locales_ru_json" */), cache: true }],
  "sv": [{ key: "../locales/sv.json", load: () => import("../locales/sv.json" /* webpackChunkName: "locale__app_locales_sv_json" */), cache: true }],
  "tr": [{ key: "../locales/tr.json", load: () => import("../locales/tr.json" /* webpackChunkName: "locale__app_locales_tr_json" */), cache: true }],
  "uk": [{ key: "../locales/uk.json", load: () => import("../locales/uk.json" /* webpackChunkName: "locale__app_locales_uk_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "ch",
      "language": "中文",
      "files": [
        "/app/locales/ch.json"
      ]
    },
    {
      "code": "da",
      "language": "Dansk",
      "files": [
        "/app/locales/da.json"
      ]
    },
    {
      "code": "de",
      "language": "Deutsch",
      "files": [
        "/app/locales/de.json"
      ]
    },
    {
      "code": "en",
      "language": "English",
      "files": [
        "/app/locales/en.json"
      ]
    },
    {
      "code": "es",
      "language": "Español",
      "files": [
        "/app/locales/es.json"
      ]
    },
    {
      "code": "fr",
      "language": "Français",
      "files": [
        "/app/locales/fr.json"
      ]
    },
    {
      "code": "nl",
      "language": "Nederlands",
      "files": [
        "/app/locales/nl.json"
      ]
    },
    {
      "code": "pl",
      "language": "Polski",
      "files": [
        "/app/locales/pl.json"
      ]
    },
    {
      "code": "pt",
      "language": "Português",
      "files": [
        "/app/locales/pt.json"
      ]
    },
    {
      "code": "ro",
      "language": "Română",
      "files": [
        "/app/locales/ro.json"
      ]
    },
    {
      "code": "ru",
      "language": "Русский",
      "files": [
        "/app/locales/ru.json"
      ]
    },
    {
      "code": "sv",
      "language": "Svenska",
      "files": [
        "/app/locales/sv.json"
      ]
    },
    {
      "code": "tr",
      "language": "Türkçe",
      "files": [
        "/app/locales/tr.json"
      ]
    },
    {
      "code": "uk",
      "language": "Українська",
      "files": [
        "/app/locales/uk.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "en",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "legacy": false,
  "locale": "en",
  "fallbackLocale": "en",
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "ch",
    "language": "中文",
    "files": [
      {
        "path": "/app/locales/ch.json"
      }
    ]
  },
  {
    "code": "da",
    "language": "Dansk",
    "files": [
      {
        "path": "/app/locales/da.json"
      }
    ]
  },
  {
    "code": "de",
    "language": "Deutsch",
    "files": [
      {
        "path": "/app/locales/de.json"
      }
    ]
  },
  {
    "code": "en",
    "language": "English",
    "files": [
      {
        "path": "/app/locales/en.json"
      }
    ]
  },
  {
    "code": "es",
    "language": "Español",
    "files": [
      {
        "path": "/app/locales/es.json"
      }
    ]
  },
  {
    "code": "fr",
    "language": "Français",
    "files": [
      {
        "path": "/app/locales/fr.json"
      }
    ]
  },
  {
    "code": "nl",
    "language": "Nederlands",
    "files": [
      {
        "path": "/app/locales/nl.json"
      }
    ]
  },
  {
    "code": "pl",
    "language": "Polski",
    "files": [
      {
        "path": "/app/locales/pl.json"
      }
    ]
  },
  {
    "code": "pt",
    "language": "Português",
    "files": [
      {
        "path": "/app/locales/pt.json"
      }
    ]
  },
  {
    "code": "ro",
    "language": "Română",
    "files": [
      {
        "path": "/app/locales/ro.json"
      }
    ]
  },
  {
    "code": "ru",
    "language": "Русский",
    "files": [
      {
        "path": "/app/locales/ru.json"
      }
    ]
  },
  {
    "code": "sv",
    "language": "Svenska",
    "files": [
      {
        "path": "/app/locales/sv.json"
      }
    ]
  },
  {
    "code": "tr",
    "language": "Türkçe",
    "files": [
      {
        "path": "/app/locales/tr.json"
      }
    ]
  },
  {
    "code": "uk",
    "language": "Українська",
    "files": [
      {
        "path": "/app/locales/uk.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
